import {Action} from "../actions/app";
import * as constants from "../constants";

export interface AuthState {
    user: any;
}

const getUser = localStorage.getItem("user");
let getUserFromStorage: any = null;
if(getUser){
  try {
    getUserFromStorage = JSON.parse(getUser);
  }
  catch (e){
  }
}

const getInitialState = () => {
    return {
        user: getUserFromStorage
    }
}

const auth = (state: AuthState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.AUTHENTICATED: {
            return {...state, user: action.payload.user};
        }

        case constants.MYPROFILE: {
            return {...state, user: action.payload.user}
        }

        case constants.LOGGED_OUT: {
            return {...state, user: null};
        }

        default: {
            return {...state};
        }

    }
}

export default auth;
