import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container, Dialog, DialogContent, DialogTitle, IconButton,
    Switch,
    Typography,
    CircularProgress, Divider
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {useHistory, useParams} from "react-router-dom";
import {
    createEventTask,
    fetchEventCode,
    getEventById,
    getEvents,
    getEventTasks, lockEvent, lockTaskInstances, deleteEventTask
} from "../../redux/actions/events";
import {groupTasksByCategory} from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import CreateTaskForm from "../../components/Forms/CreateTaskForm";
import {confirm} from "../../components/ConfirmDialog/confirm";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";


interface Props {
}

function TeamOverview(props: Props) {

    const history = useHistory();
    const dispatch: any = useDispatch();
    const {t, i18n} = useTranslation();
    const {organisationId, eventId}: any = useParams();

    const [email, setEmail] = useState("");

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)

    const [groupedTasks, setGroupedTasks] = useState<Array<any>>([]);


    const fetchData = () => {
        dispatch(getEventById(eventId)).then((res: any) => {
            dispatch(getEvents(organisationId)).then((res: any) => {
                dispatch(getEventTasks(eventId)).then((res: any) => {
                    dispatch(fetchEventCode(eventId)).then((res: any) => {
                        setLoading(false)
                    }).catch((e: any) => {
                        setLoading(false)
                    });
                }).catch((e: any) => {
                    setLoading(false)
                });
            }).then((e: any) => {
                setLoading(false)
            });
        }).catch((e: any) => {
            setLoading(false)
        });
    }

    const events = useSelector((state: RootState) => state.events.events);
    const eventTaskSummary = useSelector((state: RootState) => state.events.event);
    const eventTasks = useSelector((state: RootState) => state.events.eventTasks);
    const user = useSelector((state: RootState) => state.auth.user);

    const event = events.find((event: any) => event.id == eventId);

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setGroupedTasks(groupTasksByCategory(eventTasks));
    }, [eventTasks])

    useEffect(() => {
        setGroupedTasks(groupTasksByCategory(eventTasks));
    }, [eventTasks])
    const handleLockTask = (e: any, taskId: any) => {
        dispatch(lockTaskInstances(eventId, taskId, !e.target.checked))
            .then((res: any) => {
                fetchData();
                if (!e.target.checked) {
                    toast.success(`${t("app.error.taskOpened", "Die Aufgabe wurde geöffnet")}`);
                } else {
                    toast.success(`${t("app.error.taskClosed", "Die Aufgabe wurde geschlossen")}`);
                }
            });
    }

    const handleLockEvent = (value: any) => {
        dispatch(lockEvent(eventId, value))
            .then((res: any) => {
                fetchData()
                if (!value) {
                    toast.success(`${t("app.error.allTasksOpened", "Alle Aufgaben wurden geöffnet")}`);
                } else {
                    toast.success(`${t("app.error.allTasksOpened", "Alle Aufgaben wurden geschlossen")}`);
                }
            });
    }

    const handlePopupClose = () => {
        setOpen(false);
    };

    const handleCreateTaskClick = () => {
        setOpen(true);
    };

    const onCreateTaskFormSubmit = (values: any) => {
        dispatch(createEventTask(eventId, values))
            .then((res: any) => {
                fetchData();
                handlePopupClose();
                toast.success(`${t("app.error.newTask", "Neue Aufgabe wurde erstellt")}`);
            });
    }

    const onDeleteTask = async (task: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteEventTask(eventId, task.id))
            .then((res: any) => {
                dispatch(getEventTasks(eventId));
                toast.success(`${t("app.error.deletedTask", "Die Aufgabe wurde gelöscht")}`);
            });
    }


    return (
        loading ? (
            <div className="loader-center">
                <CircularProgress/>
            </div>
        ) : (
            <Container maxWidth="lg">
                {event && event.projectLead.invitedEmail && (
                    <Box sx={{textAlign: "left", width: "450px", mx: "auto", mt: 5}}>
                        <Typography sx={{
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                        }}>
                            {t("app.event.projectLead", "Projektleitung")} <span
                            style={{float: 'right'}}>{event.projectLead.invitedEmail}</span>
                        </Typography>
                        <Typography sx={{
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                            marginTop: '1rem'
                        }}>
                            {t("app.event.status", "Status")} <span
                            style={{float: 'right', color: 'grey'}}>{event.projectLead.status}</span>
                        </Typography>
                    </Box>
                )}
                {(event && eventTaskSummary) && (
                    <div style={{textAlign: "center"}}>
                        <Box sx={{mt: 3}}>
                            {!!groupedTasks.length &&
                                groupedTasks.map((group) => {
                                    return (
                                        <>
                                            <Box sx={{textAlign: "left", mx: "auto", mt: 5}}>
                                                <Typography sx={{fontSize: "1.2rem", mt: 2}}>
                                                    {group.categoryTitle}
                                                </Typography>
                                                {group.data.map((task: any) => {
                                                    return (
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            mt: 2
                                                        }}>
                                                            <Typography sx={{
                                                                fontSize: "1.3rem",
                                                                maxWidth: "200px"
                                                            }}>
                                                                {task.title}
                                                            </Typography>

                                                            <div>
                                                                <button
                                                                    className="yellow-button"
                                                                    style={{marginRight: '5px'}}
                                                                    onClick={() => history.push(`/organisations/${organisationId}/events/${eventId}/tasks/${task.id}`)}>
                                                                    {t("app.event.open", "offen")} {task.openSlots}
                                                                </button>
                                                                {task.openSlots > 0 ?
                                                                        <button className="grey-button">
                                                                            {t("app.event.recuse", "Wiederverwenden")}
                                                                        </button>

                                                                    :
                                                                        <button className="grey-button"
                                                                                disabled={true}>
                                                                            {t("app.event.recuse", "Wiederverwenden")}
                                                                        </button>
                                                                }
                                                            </div>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                            <Divider style={{marginTop: '10px'}}/>
                                        </>
                                    )
                                })}
                        </Box>

                        <Dialog open={open} onClose={handlePopupClose}>
                            <DialogTitle>
                                {t("app.taskTemplate.createTask", "Aufgabe erstellen")}
                                <IconButton
                                    aria-label="close"
                                    onClick={handlePopupClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500]
                                    }}>
                                    <CloseIcon/>
                                </IconButton>
                            </DialogTitle>
                            <DialogContent sx={{minWidth: "400px"}}>
                                <CreateTaskForm onSubmit={onCreateTaskFormSubmit}/>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
            </Container>
        )
    );
}

export default TeamOverview;