import React, { useEffect, useState } from 'react';
import {Box, CircularProgress } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import Content from "./Content";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {RootState} from "../../redux/reducers/rootState";
import {myprofile} from "../../redux/actions/auth";

interface Props {
    children: any;
}

const Layout = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [loading, setLoading] = useState<boolean>(true);

    const isMobile = window.innerWidth < 900;


    useEffect(() => {
        if(!user){
            history.push('/login');
        }else{
            dispatch(myprofile()).then((res: any) => {
                setLoading(false)
            }).catch((error: any) => {
                setLoading(false)
            });
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            {loading ? (
                <div className="loader">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Header/>
                    <Drawer />
                    <Content>
                        {props.children}
                    </Content>
                </>
            )}            
        </Box>
    );
}

export default Layout;
