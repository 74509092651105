import API from "../../utils/api";
import {FETCHED_ORGANIZATION_BY_ID, FETCHED_ORGANIZATIONS} from "../constants";

export const getOrganisations = () => async(dispatch: any) => {
    try {
        const response = await API.get(`organisations`);
        dispatch({ type: FETCHED_ORGANIZATIONS, payload: response.data.organizations });
        return response;
    } catch (err) {
        return err;
    }
};

export const getOrganisationById = (organisationId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`organisations/${organisationId}`);
        dispatch({ type: FETCHED_ORGANIZATION_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};


export const createOrganisation = (data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`organisations`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const updateOrganisation = (organisationId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`organisations/${organisationId}`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const changeState = (organisationId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`organisations/${organisationId}/state`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteOrganisation = (organisationId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`organisations/${organisationId}`);
        return response;
    } catch (err) {
        return err;
    }
};




