import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box, Checkbox,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    MenuItem,
    Select, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {useParams} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CreateSubtaskForm from "../../components/Forms/CreateSubtaskForm";
import {
    createEventTaskSubtask,
    fetchEventCode,
    getEventById,
    getEvents,
    getEventTasks,
    getEventTaskSubtasks,
    updateEventTask, updateEventTaskSubtask,
    deleteEventTaskSubtask,
    changeEventTaskSubtaskPosition,
    getTeamies, removeUserFromTask
} from "../../redux/actions/events";
import {confirm} from "../../components/ConfirmDialog/confirm";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

interface Props {

}

function EventTaskSubtasks(props: Props) {

    const dispatch: any = useDispatch();
    const {t, i18n} = useTranslation();

    const {organisationId, eventId, taskId}: any = useParams();

    const categories = useSelector((state: RootState) => state.categories.categories);
    const tasks = useSelector((state: RootState) => state.events.eventTasks);
    const events = useSelector((state: RootState) => state.events.events);
    const taskSubtasks = useSelector((state: RootState) => state.events.eventTaskSubtasks);
    const eventTaskSummary = useSelector((state: RootState) => state.events.event);
    const eventTeamies = useSelector((state: RootState) => state.events.eventTeamies);
    const user = useSelector((state: RootState) => state.auth.user);

    const task = tasks.find((task) => task.id == taskId);

    const [editingTask, setEditingTask] = useState<any>(null);
    const [editingSubtask, setEditingSubtask] = useState<any>(null);

    const [open, setOpen] = useState<boolean>(false);

    const fetchData = () => {
        dispatch(getEventById(eventId));
        dispatch(getEvents(organisationId));
        dispatch(getEventTasks(eventId));
        dispatch(getEventTaskSubtasks(eventId, taskId));
        dispatch(fetchEventCode(eventId));
        dispatch(getTeamies(eventId, taskId))
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handlePopupClose = () => {
        setOpen(false);
    };

    const handleCreateSubtaskClick = () => {
        setOpen(true);
    };


    const handleEditSubtask = (subtask: any) => {
        setEditingSubtask(subtask);
    }

    const handleUpdateSubtask = () => {
        dispatch(updateEventTaskSubtask(eventId, taskId, editingSubtask.id, editingSubtask))
            .then((res: any) => {
                fetchData();
                setEditingSubtask(null);
                toast.success(`${t("app.error.updateSubtask", "Die Unteraufgabe wurde aktualisiert")}`);
            });
    }

    const handleChangeTask = (property: string, value: any) => {
        setEditingTask({
            ...editingTask,
            [property]: value
        });
    }

    const handleChangeSubtask = (property: string, value: any) => {
        if (property === "mustMarkAsDone" && !value) {
            setEditingSubtask({
                ...editingSubtask,
                [property]: value,
                "daysToNotifyDeadline": null,
                "deadlineInDaysBeforeEvent": null,
            });
        } else {
             if(property === "deadlineInDaysBeforeEvent" && value == null){
               setEditingSubtask({
                 ...editingSubtask,
                 [property]: value,
                 "daysToNotifyDeadline": null,
               });
             }
             else{
               setEditingSubtask({
                 ...editingSubtask,
                 [property]: value
               });
             }

        }
    }

    const onCreateSubtaskFormSubmit = (values: any) => {
        dispatch(createEventTaskSubtask(eventId, taskId, values))
            .then((res: any) => {
                fetchData();
                handlePopupClose();
                toast.success(`${t("app.error.newSubtask", "Neue Unteraufgabe wurde erstellt")}`);
            });
    }

    const handleUpdateTask = () => {
        dispatch(updateEventTask(eventId, taskId, editingTask))
            .then((res: any) => {
                setEditingTask(null);
                fetchData();
                handlePopupClose();
                toast.success(`${t("app.error.updateSubtask", "Die Unteraufgabe wurde aktualisiert")}`);
            });
    }

    const handleEditTask = () => {
        setEditingTask(task);
    }

    const onDeleteSubtask = async (subtask: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteEventTaskSubtask(eventId, taskId, subtask.id))
            .then((res: any) => {
                dispatch(getEventTaskSubtasks(eventId, taskId));
                toast.success(`${t("app.error.deleteSubtask", "Die Unteraufgabe wurde gelöscht")}`);
            });
    }

    const onDeleteUser = async (user: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(removeUserFromTask(taskId, user.id))
            .then((res: any) => {
                dispatch(getTeamies(eventId, taskId));
                toast.success(`${t("app.event.removeUserMessage", "Der Benutzer wurde entfernt")}`);
            });
    }

    return (
        task ? <Container maxWidth="md">
                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.category", "Kategorie")}:
                </Typography>
                <Typography sx={{fontSize: "1rem", fontWeight: "bold"}}>
                    {editingTask ?
                        <FormControl
                            sx={{width: "400px"}}
                            margin="normal"
                            variant="standard">
                            <Select
                                labelId="category-input-label"
                                id="category-input"
                                label="Category"
                                name="categoryId"
                                value={editingTask.categoryId}
                                onChange={(e) => handleChangeTask("categoryId", e.target.value)}>
                                {categories.map((category: any) =>
                                    <MenuItem value={category.id}>{category.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl> : task.category.title}
                </Typography>
                <Typography sx={{fontSize: "1.4rem", fontWeight: "bold"}}>
                    {editingTask ?
                        <Input
                            fullWidth
                            size="medium"
                            value={editingTask.title}
                            onChange={(e) => handleChangeTask("title", e.target.value)}
                        /> : task.title}
                </Typography>
                <Typography sx={{fontWeight: "bold"}}>
                    {editingTask ?
                        <Input
                            sx={{mt: 2}}
                            fullWidth
                            size="medium"
                            value={editingTask.description}
                            onChange={(e) => handleChangeTask("description", e.target.value)}
                        /> : task.description}
                </Typography>

                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.ingredients", "Zutaten")}:
                </Typography>
                <Typography>
                    {editingTask ?
                        <Input
                            fullWidth
                            size="medium"
                            value={editingTask.ingredients}
                            onChange={(e) => handleChangeTask("ingredients", e.target.value)}
                        /> : task.ingredients}
                </Typography>

                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.difficulty", "Schwierigkeit")}:
                </Typography>

                <Typography sx={{fontSize: "1.2rem"}}>
                    {editingTask ?
                        <FormControl
                            sx={{width: "400px"}}
                            margin="normal"
                            variant="standard">
                            <Select
                                labelId="difficulty-input-label"
                                id="difficulty-input"
                                label="Difficulty"
                                name="difficultyId"
                                value={editingTask.difficulty}
                                onChange={(e) => handleChangeTask("difficulty", e.target.value)}>
                                <MenuItem value="Easy">{t("app.subtask.difficulty.easy", "Einfach")}</MenuItem>
                                <MenuItem
                                    value="Moderate">{t("app.subtask.difficulty.moderate", "Mittel")}</MenuItem>
                                <MenuItem
                                    value="Difficult">{t("app.subtask.difficulty.difficult", "Herausfordernd")}</MenuItem>
                            </Select>
                        </FormControl> : task.difficulty}
                </Typography>

                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.duration", "Duration")}:
                </Typography>
                <Typography>
                    {editingTask ?
                        <Input
                            fullWidth
                            size="medium"
                            value={editingTask.duration}
                            onChange={(e) => handleChangeTask("duration", e.target.value)}
                        /> : task.duration}
                </Typography>

                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.maxNumberOfUsers", "Maximale Anzahl an Teilnehmern")}:
                </Typography>
                <Typography sx={{fontSize: "1.2rem"}}>
                    {editingTask ?
                        <Input
                            type="number"
                            fullWidth
                            size="medium"
                            value={editingTask.maxNumberOfUsers}
                            onChange={(e) => handleChangeTask("maxNumberOfUsers", e.target.value ? parseInt(e.target.value) : "")}
                        /> : task.maxNumberOfUsers}
                </Typography>
                <Typography sx={{fontWeight: "bold", mt: 2}}>
                    {t("app.tasks.informationForUsers", "Informationen für Benutzer")}:
                </Typography>
                <div style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}>
                  <div style={{display: 'block'}}>
                    <Typography noWrap>
                        {t("app.event.inAppInformation", "In-App-Informationen")}
                    </Typography>
                    <Switch
                      defaultChecked={task.inAppNotification}
                      disabled={!editingTask}
                      onChange={(e) => handleChangeTask("inAppNotification", e.target.checked)}
                    />
                  </div>
                  <div style={{display: 'block', marginLeft: '2rem'}}>
                    <Typography noWrap>
                        {t("app.event.emailInformation", "E-Mail-Informationen")}
                    </Typography>
                    <Switch
                      defaultChecked={task.emailNotification}
                      disabled={!editingTask}
                      onChange={(e) => handleChangeTask("emailNotification", e.target.checked)}
                    />
                  </div>
                  <div style={{display: 'block', marginLeft: '2rem'}}>
                    <Typography noWrap>
                      {t("app.event.projectLeadEmailInformation", "Email an die Projektleitung An/Aus")}
                    </Typography>
                    <Switch
                      defaultChecked={task.projectLeadEmailNotification}
                      disabled={!editingTask}
                      onChange={(e) => handleChangeTask("projectLeadEmailNotification", e.target.checked)}
                    />
                  </div>
                </div>
                <Typography sx={{width: '100%', fontSize: '0.8rem', fontStyle: 'italic'}}>
                    ({t("app.tasks.informationText", "Dieser Hinweis ist für Teilnehmer einer Aufgabe, wenn diese von mehreren übernommen werden kann. Um keinen Hinweis anzuzeigen, bitte das Feld leer lassen")}.)
                </Typography>
                <Typography style={{width: '100%', paddingTop: '0.7rem'}}>
                    {editingTask ?
                        <TextField
                            sx={{mt: 1}}
                            fullWidth={true}
                            multiline
                            rows={4}
                            value={editingTask.note}
                            onChange={(e) => handleChangeTask("note", e.target.value)}
                        /> : task.note}
                </Typography>

                <Box sx={{mt: 3}}>
                    {editingTask
                        ? <button className="yellow-button"
                                  onClick={handleUpdateTask}>{t("app.tasks.toSave", "speichern")}</button>
                        : <button className="yellow-button"
                                  onClick={handleEditTask}>{t("app.organizer.toEdit", "bearbeiten")}</button>}
                </Box>
                <div style={{display: 'flex', marginTop: '2rem', marginBottom: '2rem'}}>
                    <div style={{marginRight: '3rem'}}>
                        <Typography sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                            lineHeight: "2rem",
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 1
                        }}>
                            {task.openSlots}
                        </Typography>
                        <Typography className="text">
                            {t("app.event.positionsStillOpen", "Stellen noch offen")}
                        </Typography>
                    </div>
                    <div>
                        <Typography sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                            lineHeight: "2rem",
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 1
                        }}>
                            {task.occupiedSlots}
                        </Typography>
                        <Typography className="text">
                            {t("app.event.positionsOccupied", "Positionen besetzt")}
                        </Typography>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "column", width: "400px", marginBottom: "2rem"}}>
                    <Typography className="text1">
                        {t("app.event.assignedUsers", "Zugewiesene Benutzer")}:
                    </Typography>
                    {eventTeamies && eventTeamies.map((teamies: any) =>
                        <Typography sx={{fontSize: "1.2rem", fontWeight: "bold"}}>
                            {teamies.firstname} {teamies.lastname}
                            <button className="grey-button" style={{float: "right"}}
                                    onClick={() => onDeleteUser(teamies)}
                            >
                                {t("app.event.removeUser", "Entfernen")}
                            </button>
                        </Typography>
                    )}
                </div>

                <>
                    <Typography sx={{fontWeight: "bold", mt: 2}}>
                        {t("app.tasks.manual", "Anleitung")}
                    </Typography>
                    <DragDropContext
                        onDragEnd={(param) => {
                            const src = param.source.index;
                            const des = param.destination?.index;
                            if (typeof des === "number") {
                                taskSubtasks.splice(des, 0, taskSubtasks.splice(src, 1)[0]);
                                let newSubtasksOrder: any = [];
                                taskSubtasks.forEach((subtask, index) => {
                                    newSubtasksOrder.push({"id": subtask.id, "position": index});
                                })
                                dispatch(changeEventTaskSubtaskPosition(taskId, {"subtasks": newSubtasksOrder})).then((res: any) => {
                                    fetchData();
                                });
                            }
                        }}
                    >
                        <TableContainer>
                            <Table sx={{'th': {fontSize: ".8rem", fontWeight: "bold", borderColor: "black"}}}
                                   aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{p: 0}}>{t("app.events.createEvent.title", "Titel")}</TableCell>
                                        <TableCell
                                            align="center">{t("app.tasks.mustBeDone", "Muss als erledigt markiert werden")}</TableCell>
                                        <TableCell
                                            align="center">{t("app.tasks.deadline", "Fertigstellungstermin in Tagen vor der Veranstaltung")}</TableCell>
                                        <TableCell
                                            align="center">{t("app.tasks.info", "Erinnerung senden x Tage vor Fertigstellungstermin"
                                        )}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <TableBody
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}>
                                            {taskSubtasks.sort((a, b) => a.position > b.position ? 1 : -1).map
                                            ((subtask, index) => {
                                                return (
                                                    <Draggable key={subtask.id} draggableId={"draggable" + subtask.id}
                                                               index={index}>
                                                        {(provided, snapshot) => (
                                                            <TableRow sx={{'td': {border: "none", py: 1}}}
                                                                      ref={provided.innerRef}
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                            >
                                                                <TableCell>
                                                                    <div style={{display: 'flex'}}>
                                                                        <DragHandleIcon style={{marginRight: '5px'}}/>
                                                                        <Typography sx={{fontSize: "1rem"}}>
                                                                            {editingSubtask?.id === subtask.id
                                                                                ? <Input
                                                                                    fullWidth
                                                                                    size="medium"
                                                                                    value={editingSubtask.title}
                                                                                    onChange={(e: any) => handleChangeSubtask("title", e.target.value)}
                                                                                /> : subtask.title}
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {editingSubtask?.id === subtask.id ? <Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                        checked={editingSubtask.mustMarkAsDone}
                                                                        onChange={(e: any) =>
                                                                            handleChangeSubtask("mustMarkAsDone", Boolean(e.target.checked))}
                                                                    /> : subtask.mustMarkAsDone && <Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                        checked/>}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Typography sx={{fontSize: "1rem"}}>
                                                                        {editingSubtask?.id === subtask.id ?
                                                                            <TextField id="outlined-basic"
                                                                                       size="small"
                                                                                       variant="outlined"
                                                                                       disabled={!editingSubtask.mustMarkAsDone}
                                                                                       value={editingSubtask.deadlineInDaysBeforeEvent}
                                                                                       onChange={(e) => handleChangeSubtask("deadlineInDaysBeforeEvent", e.target.value ? parseInt(e.target.value) : null)}
                                                                            />
                                                                            : subtask.deadlineInDaysBeforeEvent }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Typography sx={{fontSize: "1rem"}}>
                                                                        {editingSubtask?.id === subtask.id ?
                                                                            <TextField id="outlined-basic"
                                                                                       size="small"
                                                                                       variant="outlined"
                                                                                       disabled={!editingSubtask.mustMarkAsDone || editingSubtask.deadlineInDaysBeforeEvent == null}
                                                                                       value={editingSubtask.daysToNotifyDeadline}
                                                                                       onChange={(e) => handleChangeSubtask("daysToNotifyDeadline", e.target.value ? parseInt(e.target.value) : null)}
                                                                            />
                                                                            : subtask.daysToNotifyDeadline }
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell style={{display: 'flex'}}>
                                                                    <>
                                                                        {editingSubtask?.id === subtask.id
                                                                            ? <button className="yellow-button"
                                                                                      style={{marginRight: '10px'}}
                                                                                      onClick={handleUpdateSubtask}>{t("app.tasks.toSave", "speichern")}</button>
                                                                            : <button className="yellow-button"
                                                                                      style={{marginRight: '10px'}}
                                                                                      onClick={() => handleEditSubtask(subtask)}>{t("app.organizer.toEdit", "bearbeiten",)}</button>}
                                                                        <button className="grey-button"
                                                                                onClick={() => onDeleteSubtask(subtask)}>
                                                                            {t("app.organizer.delete", "löschen")}
                                                                        </button>
                                                                    </>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </Table>
                        </TableContainer>
                    </DragDropContext>


                    <Box sx={{width: "150px", mx: "auto", mt: 4, mb: 2}}>
                        <button className="yellow-button"
                                onClick={handleCreateSubtaskClick}>{t("app.tasks.addTo", "Hinzufügen")}</button>
                    </Box>

                </>

                <Dialog open={open} onClose={handlePopupClose}>
                    <DialogTitle>
                        {t("app.tasks.createSubtasks", "Unteraufgabe erstellen")}

                        <IconButton
                            aria-label="close"
                            onClick={handlePopupClose}
                            sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{minWidth: "500px"}}>
                        <CreateSubtaskForm onSubmit={onCreateSubtaskFormSubmit}/>
                    </DialogContent>
                </Dialog>

            </Container>
            : null
    );
}

export default EventTaskSubtasks;
