import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {Provider} from "react-redux";
import store from "./redux/store";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {LocalizationProvider} from "@mui/lab";
import AdapterMoment from '@mui/lab/AdapterMoment';
import i18n from './i18n';
import {I18nextProvider} from "react-i18next";

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <I18nextProvider i18n={i18n}>
                <CssBaseline/>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            duration: 7000,
                            error: {
                                iconTheme: {
                                    primary: 'red',
                                    secondary: 'white',
                                },
                            },
                            success: {
                                iconTheme: {
                                    primary: '#FFFF00',
                                    secondary: 'white',
                                },
                            }
                        }}
                    />
                <Router>
                    <Route path="/" component={App}/>
                </Router>
                </I18nextProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
