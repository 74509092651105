import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    CircularProgress,
    Divider
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {useHistory, useParams} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {confirm} from "../../components/ConfirmDialog/confirm";
import {
    createCategories,
    createCategoriesByOrganisation,
    deleteCategories, deleteCategoriesByOrganisation,
    getCategories, getCategoriesByOrganisation,
    updateCategories, updateCategoriesByOrganisation
} from "../../redux/actions/categories";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import CreateCategoryForm from "../../components/Forms/CreateCategoryForm";
import UpdateCategoryForm from "../../components/Forms/UpdateCategoryForm";
import organisations from "../../redux/reducers/categories";
import UpdateEventCategoryForm from "../../components/Forms/UpdateEventCategoryForm";
import CreateEventCategoryForm from "../../components/Forms/CreateEventCategoryForm";

interface Props {

}

function EventCategories(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const {organisationId}: any = useParams();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [editingCategory, setEditingCategory] = useState<any>(null);

    const categories = useSelector((state: RootState) => state.categories.categories);
    const user = useSelector((state: RootState) => state.auth.user);

    const fetchCategoriesByOrganisationId = () => {
        if (isOrganisationAdmin(user.adminOfOrganisationIds) || user.admin) {
            dispatch(getCategoriesByOrganisation(organisationId)).then((res: any) => {
                setLoading(false);
            }).catch((e: any) => {
                setLoading(false);
            });
        }else{
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCategoriesByOrganisationId();
    }, [])

    const handlePopupClose = () => {
        setOpen(false);
        setEditingCategory(null);
    };

    const handleCreateCategoryClick = () => {
        setEditingCategory(null);
        setOpen(true);
    };

    const handleEditCategoryClick = (category: any) => {
        setEditingCategory(category);
        setOpen(true);
    };

    const onCreateCategoryFormSubmit = (values: any) => {
        dispatch(createCategoriesByOrganisation(organisationId, {...values, organization_id: parseInt(organisationId)}))
            .then((res: any) => {
                fetchCategoriesByOrganisationId();
                handlePopupClose()
                toast.success(`${t("app.error.categoryCreated", "Eine neue Kategorie wurde erstellt")}`);
            });
    }

    const onUpdateCategoryFormSubmit = (values: any) => {
        dispatch(updateCategoriesByOrganisation(organisationId, editingCategory.id, values))
            .then((res: any) => {
                fetchCategoriesByOrganisationId();
                handlePopupClose();
                toast.success(`${t("app.error.categoryUpdated", "Die Kategorie wurde aktualisiert")}`);
            });
    }

    const onDeleteCategory = async (category: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteCategoriesByOrganisation(organisationId, category.id))
            .then((res: any) => {
                fetchCategoriesByOrganisationId();
                toast.success(`${t("app.error.categoryDeleted", "Die Kategorie wurde gelöscht")}`);
            });
    }

    const isOrganisationAdmin = (organisationIds: number[]) => {
        return organisationIds.some((id: number) => id === parseInt(organisationId));
    }

    return (
        <Container maxWidth="lg">
            {/*loading ? (*/}
            {/*<div className="loader-center">*/}
            {/*    <CircularProgress/>*/}
            {/*</div>*/}
            {/*) : (*/}
            <>
                {!user.admin &&
                    isOrganisationAdmin(user.adminOfOrganisationIds) && (                    <div style={{display: 'flex', justifyContent: 'end', marginTop: '1rem'}}>
                        <button className="yellow-button"
                                onClick={handleCreateCategoryClick}>{t("app.category.create", "Kategorie erstellen")}</button>
                    </div>
                )}
                <Box sx={{mt: 3}}>
                    {categories.map((category) => {
                        return (
                            <>
                                <div className="categories">
                                    <Box sx={{textAlign: "left", mx: "auto", mt: 5}}>
                                        <Typography sx={{fontSize: "1.2rem", mt: 2}}>
                                            {category.title}
                                        </Typography>
                                    </Box>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            {!category.requiredForCultureManager ? (
                                                <>
                                                    <button style={{marginRight: '5px'}} className="yellow-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditCategoryClick(category);
                                                            }}
                                                    >
                                                        {t("app.organizer.toEdit", "Bearbeiten")}</button>
                                                    <button
                                                        className="grey-button"
                                                        onClick={() => onDeleteCategory(category)}>
                                                        {t("app.organizer.delete", "löschen")}</button>
                                                </>
                                            ) : (
                                                <Typography>Required for certification</Typography>
                                            )
                                            }
                                        </div>
                                </div>
                            </>
                        )
                    })}
                </Box>

                <Dialog open={open}>
                    <DialogTitle>
                        {t("app.category.dialogTitle", "Bitte geben Sie Ihre Kategorie ein")}

                        <IconButton
                            aria-label="close"
                            onClick={handlePopupClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500]
                            }}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        {editingCategory ?
                            editingCategory &&
                            <UpdateEventCategoryForm category={editingCategory} onSubmit={onUpdateCategoryFormSubmit}/>
                            : <CreateEventCategoryForm onSubmit={onCreateCategoryFormSubmit}/>}

                    </DialogContent>
                </Dialog>
            </>
        </Container>
    );
}

export default EventCategories;
