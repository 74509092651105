import React, {useState, useContext} from "react";

import {ReactComponent as Camera} from "../../assets/icons/camera_icon.svg";
import {ReactComponent as Close} from "../../assets/icons/close_cross.svg";
import {Button, Input} from "@mui/material";
import {useTranslation} from "react-i18next";

const ImageUpload = (props: any) => {
    const {t, i18n} = useTranslation();
    const {imageFile} = props;
    const [image, setImage] = useState<any>(null);

    const onImageChange = (event: any) => {
        const maxfilesize = 1024 * 1024;

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size <= maxfilesize) {
                imageFile(event.target.files[0]);
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    setImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    };
    return (
        <>
            <section
                style={{
                    margin: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {image ? (
                    <div style={{display: "flex"}}>
                        <Close
                            className={"close-icon"}
                            width={10}
                            height={10}
                            fill={"black"}
                            style={{order: 1}}
                            onClick={() => setImage(null)}
                        />

                        <img
                            style={{height: "120px", margin: "30px auto 0"}}
                            src={image}
                            alt={"organisation"}
                        />
                    </div>
                ) : (
                    <Camera
                        style={{
                            backgroundColor: "lightgray",
                            borderWidth: 0,
                            borderColor: "rgb(140,140,140,0 )",
                            width: 150,
                            height: 150,
                            borderRadius: 90,
                            padding: 20,
                        }}
                    />
                )}
            </section>

            <Button
                fullWidth
                variant="contained"
                component="label"
                style={{alignSelf: "center"}}
            >
                {t("app.event.uploadImage", "BILD HOCHLADEN")}
                <input type="file" name="image" hidden onChange={onImageChange}/>
            </Button>
        </>
    );
};

export default ImageUpload;
