import {Action} from "../actions/app";
import * as constants from "../constants";

export interface EventsState {
    events: Array<any>;
    event: any;
    eventTasks: Array<any>;
    eventTask: any;
    eventTaskSubtasks: Array<any>;
    eventTaskSubtask: any;
    eventCode: string;
    eventTeamies: any;
}

const getInitialState = () => {
    return {
        events: [],
        event: null,
        eventTasks: [],
        eventTask: null,
        eventTaskSubtasks: [],
        eventTaskSubtask: null,
        eventCode: "",
        eventTeamies: []
    }
}

const events = (state: EventsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_EVENTS: {
            return {...state, events: action.payload};
        }

        case constants.FETCHED_EVENT_BY_ID: {
            return {...state, event: action.payload};
        }

        case constants.FETCHED_EVENT_TASKS: {
            return {...state, eventTasks: action.payload};
        }

        case constants.FETCHED_EVENT_TASK_BY_ID: {
            return {...state, eventTask: action.payload};
        }

        case constants.FETCHED_EVENT_TASK_SUBTASKS: {
            return {...state, eventTaskSubtasks: action.payload};
        }

        case constants.FETCHED_EVENT_TASK_SUBTASK_BY_ID: {
            return {...state, eventTaskSubtask: action.payload};
        }

        case constants.FETCHED_EVENT_CODE: {
            return {...state, eventCode: action.payload};
        }

        case constants.FETCHED_TEAMIES: {
            return {...state, eventTeamies: action.payload};
        }

        case constants.LOGGED_OUT: {
            return {...getInitialState()}
        }

        default: {
            return {...state};
        }

    }
}

export default events;
