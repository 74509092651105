import API from "../../utils/api";
import {
    FETCHED_TASK_BY_ID, FETCHED_TASK_SUBTASK_BY_ID,
    FETCHED_TASK_SUBTASKS,
    FETCHED_TASKS
} from "../constants";

export const getTasks = () => async(dispatch: any) => {
    try {
        const response = await API.get(`task-templates`);
        dispatch({ type: FETCHED_TASKS, payload: response.data.tasks });
        return response;
    } catch (err) {
        return err;
    }
};

export const getTaskById = (taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`task-templates/${taskId}`);
        dispatch({ type: FETCHED_TASK_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};


export const createTask = (data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`task-templates`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const updateTask = (taskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`task-templates/${taskId}`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteTask = (taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`task-templates/${taskId}`);
        return response;
    } catch (err) {
        return err;
    }
};

export const getTaskSubtasks = (taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`task-templates/${taskId}/subtask-templates`);
        dispatch({ type: FETCHED_TASK_SUBTASKS, payload: response.data.subtasks });
        return response;
    } catch (err) {
        return err;
    }
};

export const getTaskSubtaskById = (taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`task-templates/${taskId}`);
        dispatch({ type: FETCHED_TASK_SUBTASK_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};


export const createSubtask = (taskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`task-templates/${taskId}/subtask-templates`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const updateSubtask = (subtaskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`subtask-templates/${subtaskId}`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteSubTask = (subTaskId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`subtask-templates/${subTaskId}`);
        return response;
    } catch (err) {
        return err;
    }
};

export const changeSubtaskPosition = ( data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`subtask-templates`, data);
        return response;
    } catch (err) {
        return err;
    }
}


