export const SET_LOADING = "SET_LOADING";
export const SET_DRAWER = "SET_DRAWER";
export const SET_RIGHT_DRAWER = "SET_RIGHT_DRAWER";

export const FETCHED_ORGANIZATIONS = "FETCHED_ORGANIZATIONS";
export const FETCHED_ORGANIZATION_BY_ID = "FETCHED_ORGANIZATION_BY_ID";

export const FETCHED_EVENTS = "FETCHED_EVENTS";
export const FETCHED_EVENT_BY_ID = "FETCHED_EVENT_BY_ID";
export const FETCHED_EVENT_CODE = "FETCHED_EVENT_CODE";
export const FETCHED_TEAMIES = "FETCHED_TEAMIES";

export const FETCHED_EVENT_TASKS = "FETCHED_EVENT_TASKS";
export const FETCHED_EVENT_TASK_BY_ID = "FETCHED_EVENT_TASK_BY_ID";

export const FETCHED_EVENT_TASK_SUBTASKS = "FETCHED_EVENT_TASK_SUBTASKS";
export const FETCHED_EVENT_TASK_SUBTASK_BY_ID = "FETCHED_EVENT_TASK_SUBTASK_BY_ID";

export const FETCHED_TASKS = "FETCHED_TASKS";
export const FETCHED_TASK_BY_ID = "FETCHED_TASK_BY_ID";

export const FETCHED_TASK_SUBTASKS = "FETCHED_TASK_SUBTASKS";
export const FETCHED_TASK_SUBTASK_BY_ID = "FETCHED_TASK_SUBTASK_BY_ID";

export const FETCHED_CATEGORIES = "FETCHED_CATEGORIES";

export const AUTHENTICATED = "AUTHENTICATED";
export const MYPROFILE = "MYPROFILE";
export const LOGGED_OUT = "LOGGED_OUT";

