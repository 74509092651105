import {Action} from "../actions/app";
import * as constants from "../constants";

export interface OrganisationsState {
    organisations: Array<any>;
    organisation: any;
}

const getInitialState = () => {
    return {
        organisations: [],
        organisation: null,
    }
}

const organisations = (state: OrganisationsState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_ORGANIZATIONS: {
            return {...state, organisations: action.payload};
        }

        case constants.FETCHED_ORGANIZATION_BY_ID: {
            return {...state, organisation: action.payload.organization};
        }

        case constants.LOGGED_OUT: {
            return {...getInitialState()}
        }

        default: {
            return {...state};
        }

    }
}

export default organisations;
