import API from "../../utils/api";
import {
    FETCHED_EVENT_BY_ID, FETCHED_EVENT_CODE,
    FETCHED_EVENT_TASK_BY_ID, FETCHED_EVENT_TASK_SUBTASK_BY_ID,
    FETCHED_EVENT_TASK_SUBTASKS,
    FETCHED_EVENT_TASKS,
    FETCHED_EVENTS, FETCHED_TEAMIES
} from "../constants";
import {convertObjectToFormData} from "../../utils";
import {dispatch} from "react-hot-toast/dist/core/store";

export const getEvents = (organizationId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`organisations/${organizationId}/events`);
        dispatch({ type: FETCHED_EVENTS, payload: response.data.events });
        return response;
    } catch (err) {
        return err;
    }
};

export const createEvent = (organizationId: string, data: any) => async(dispatch: any) => {
    const formData = convertObjectToFormData(data);
    try {
        const response = await API.post(`organisations/${organizationId}/events`, formData);
        return response;
    } catch (err) {
        return err;
    }
};

export const updateEvent = (eventId: string, data: any) => async(dispatch: any) => {
    const formData = convertObjectToFormData(data);
    try {
        const response = await API.put(`events/${eventId}`, formData);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteEvent = (eventId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`events/${eventId}`);
        return response;
    } catch (err) {
        return err;
    }
};


export const getEventById = (eventId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`tasksummary/${eventId}`);
        dispatch({ type: FETCHED_EVENT_BY_ID, payload: response.data.tasksummary });
        return response;
    } catch (err) {
        return err;
    }
};

export const getEventTasks = (eventId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`event/${eventId}/tasks`);
        dispatch({ type: FETCHED_EVENT_TASKS, payload: response.data.tasks });
        return response;
    } catch (err) {
        return err;
    }
};

export const getEventTaskById = (eventId: string, taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`events/${eventId}/tasks/${taskId}`);
        dispatch({ type: FETCHED_EVENT_TASK_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const createEventTask = (eventId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`events/${eventId}/tasks`, data);
        dispatch({ type: FETCHED_EVENT_TASK_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const updateEventTask = (eventId: string, taskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`events/${eventId}/tasks/${taskId}`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteEventTask = (eventId: string, taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`events/${eventId}/tasks/${taskId}`);
        return response;
    } catch (err) {
        return err;
    }
};

export const getEventTaskSubtasks = (eventId: string, taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`event/${eventId}/${taskId}/subtasks`);
        dispatch({ type: FETCHED_EVENT_TASK_SUBTASKS, payload: response.data.subtasks });
        return response;
    } catch (err) {
        return err;
    }
};

export const getEventTaskSubtaskById = (eventId: string, taskId: string, subtaskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`events/${eventId}/tasks/${taskId}/subtasks/${subtaskId}`);
        dispatch({ type: FETCHED_EVENT_TASK_SUBTASK_BY_ID, payload: response.data });
        return response;
    } catch (err) {
        return err;
    }
};

export const createEventTaskSubtask = (eventId: string, taskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`tasks/${taskId}/subtasks`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const updateEventTaskSubtask = (eventId: string, taskId: string, subtaskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`subtasks/${subtaskId}`, data);
        return response;
    } catch (err) {
        return err;
    }
};

export const deleteEventTaskSubtask = (eventId: string, taskId: string, subtaskId: string) => async(dispatch: any) => {
    try{
        const response = await API.delete(`event/${eventId}/${taskId}/subtasks/${subtaskId}`);
        return response;
    } catch(err) {
        return err;
    }
}

export const handleParticipation = (eventId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`events/${eventId}/participation`, data);
        return response;
    } catch (err) {
        throw err;
    }
};

export const fetchEventCode = (eventId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`events/${eventId}/code`);
        dispatch({ type: FETCHED_EVENT_CODE, payload: response.data.code });
        return response;
    } catch (err) {
        throw err;
    }
};

export const inviteEventProjectLead = (eventId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.post(`events/${eventId}/invite-project-lead`, data);
        return response;
    } catch (err) {
        throw err;
    }
};

export const lockTaskInstances = (eventId: string, taskId: string, locked: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`locktaskinstances/${eventId}/${taskId}/${locked}`);
        return response;
    } catch (err) {
        throw err;
    }
};

export const lockEvent = (eventId: string, locked: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`lockevent/${eventId}/${locked}`);
        return response;
    } catch (err) {
        throw err;
    }
};

export const getTeamies = (eventId: string, taskId: string) => async(dispatch: any) => {
    try {
        const response = await API.get(`teamies/${eventId}/${taskId}`);
        dispatch({ type: FETCHED_TEAMIES, payload: response.data.teamies});
        return response;
    } catch (err) {
        return err;
    }
}

export const changeEventTaskSubtaskPosition = (taskId: string, data: any) => async(dispatch: any) => {
    try {
        const response = await API.put(`tasks/${taskId}/subtasks`, data);
        return response;
    } catch (err) {
        return err;
    }
}

export const removeUserFromTask = (taskId: string, userId: string) => async(dispatch: any) => {
    try {
        const response = await API.delete(`tasks/${taskId}/users/${userId}`);
        return response;
    } catch (err) {
        return err;
    }
};

