import {Action} from "../actions/app";
import * as constants from "../constants";

export interface CategoriesState {
    categories: Array<any>;
}

const getInitialState = () => {
    return {
        categories: [],
    }
}

const organisations = (state: CategoriesState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_CATEGORIES: {
            return {...state, categories: action.payload};
        }

        case constants.LOGGED_OUT: {
            return {...getInitialState()}
        }

        default: {
            return {...state};
        }

    }
}

export default organisations;
