import React, {useEffect} from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import {useTranslation} from "react-i18next";


interface Props {
    onSubmit: any;
}

const CreateTaskForm = (props: Props) => {

    const {t, i18n} = useTranslation();

    const validationSchema = yup.object({
        title: yup.string().required(`${t("app.error.title", "Titel ist erforderlich")}`),
        description: yup.string().required(`${t("app.error.description", "Beschreibung ist erforderlich")}`),
        difficulty: yup.string().required(`${t("app.error.difficulty", "Schwierigkeit ist erforderlich")}`),
        duration: yup.string().required(`${t("app.error.duration", "Dauer ist erforderlich")}`),
        ingredients: yup.string().required(`${t("app.error.ingredients", "Zutaten ist erforderlich")}`),
        categoryId: yup.string().required(`${t("app.error.category", "Kategorie ist erforderlich")}`),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            difficulty: "",
            duration: "",
            ingredients: "",
            categoryId: "",
            categoryTitle: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const categories = useSelector((state: RootState) => state.categories.categories);

    return (
        <form onSubmit={formik.handleSubmit}>

            <TextField
                fullWidth
                label={t("app.event.title", "Titel")}
                name="title"
                margin="normal"
                variant="standard"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}/>

            <TextField
                fullWidth
                multiline
                rows={5}
                label={t("app.event.description", "Beschreibung")}
                name="description"
                variant="standard"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}/>

            <TextField
                fullWidth
                label={t("app.tasks.ingredients", "Zutaten")}
                name="ingredients"
                margin="normal"
                variant="standard"
                value={formik.values.ingredients}
                onChange={formik.handleChange}
                error={formik.touched.ingredients && Boolean(formik.errors.ingredients)}
                helperText={formik.touched.ingredients && formik.errors.ingredients}/>


            <FormControl
                fullWidth
                margin="normal"
                variant="standard"
                error={formik.touched.difficulty && Boolean(formik.errors.difficulty)}>
                <InputLabel id="difficulty-input-label">{t("app.tasks.difficulty", "Schwierigkeit")}</InputLabel>
                <Select
                    labelId="difficulty-input-label"
                    id="difficulty-input"
                    label={t("app.tasks.difficulty", "Schwierigkeit")}
                    name="difficulty"
                    value={formik.values.difficulty}
                    onChange={formik.handleChange}>
                    <MenuItem value="Easy">{t("app.subtask.difficulty.easy", "Einfach")}</MenuItem>
                    <MenuItem value="Moderate">{t("app.subtask.difficulty.moderate", "Mittel")}</MenuItem>
                    <MenuItem value="Difficult">{t("app.subtask.difficulty.difficult", "Herausfordernden")}</MenuItem>
                </Select>
                <FormHelperText>{formik.touched.difficulty && formik.errors.difficulty}</FormHelperText>
            </FormControl>


            <TextField
                fullWidth
                label={t("app.subtask.duration", "Dauer")}
                name="duration"
                margin="normal"
                variant="standard"
                value={formik.values.duration}
                onChange={formik.handleChange}
                error={formik.touched.duration && Boolean(formik.errors.duration)}
                helperText={formik.touched.duration && formik.errors.duration}/>

            <FormControl
                fullWidth
                margin="normal"
                variant="standard"
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}>
                <InputLabel id="category-input-label">{t("app.tasks.category", "Kategorie")}</InputLabel>
                <Select
                    labelId="category-input-label"
                    id="category-input"
                    label={t("app.tasks.category", "Kategorie")}
                    name="categoryId"
                    value={formik.values.categoryId}
                    onChange={formik.handleChange}>
                    {categories.map((category: any) =>
                        <MenuItem value={category.id}>{category.title}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{formik.touched.categoryId && formik.errors.categoryId}</FormHelperText>
            </FormControl>

            <div style={{textAlign: "center"}}>
                <LoadingButton
                    sx={{mt: 2}}
                    type="submit"
                    variant="contained">
                    {t("app.organizer.create", "erstellen")}
                </LoadingButton>
            </div>

        </form>
    );
}

export default CreateTaskForm;
