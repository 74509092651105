import {Input} from "@mui/material";
import React from "react";

interface Props {

}

export default function CustomInput(props: any) {

    return (
        <Input {...props}
            sx={{
                border: "none",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.2rem",
                // ":after": { display: "none" },
                ":before": { display: "none" },
                "input": { textAlign: "center" },
                ...props.sx,
            }}/>
    )
}
