import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import {
    Divider,
    Drawer as MaterialUiDrawer,
    List,
    ListItem,
    styled, Typography
} from "@mui/material";
import {setDrawer} from "../../../redux/actions/app";
import {useHistory} from "react-router-dom";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/calendar_icon.svg";
import {useTranslation} from "react-i18next";
import Logo from "../../../assets/images/logo.png"
import Parti from "../../../assets/images/parti.png"
import MenuIcon from '@mui/icons-material/Menu';

interface Props {

}

const drawerWidth = 240;
const isMobile = window.innerWidth < 768;


const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1, 0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));

const sxConfig = {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
    },
    '>div': {
        borderRight: "1px solid black",
    }
}

const Drawer = (props: Props) => {

    const history = useHistory();

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const isDrawerOpen = useSelector((state: RootState) => state.app.isDrawerOpen);
    const user = useSelector((state: RootState) => state.auth.user);


    const handleDrawerClose = () => {
        dispatch(setDrawer(false));
    }

    const adminLinks = {
        items: [
            {link: "/organisations", title: `${t("app.home.organizer", "Veranstalter:in")}`, icon: <CalendarIcon/>},
            {link: "/tasks", title: `${t("app.home.taskTemplates", "Aufgaben Vorlagen")}`, icon: <CalendarIcon/>},
        ]
    }

    const userLinks = {
        items: [
            {link: "/organisations", title: `${t("app.home.organizer", "Veranstalter:in")}`, icon: <CalendarIcon/>},
        ]
    }

    const handleListItemClick = (path: string) => {
        history.push(path);
    }

    return (
        <MaterialUiDrawer
            sx={sxConfig}
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}>
            {/*<Divider/>*/}
            <div className="logo">
                <img style={{width: '165px', margin: '2rem 4rem 0', cursor: 'pointer', backgroundColor: 'white'}}
                     src={Logo} onClick={() => history.push('/organisations')}/>
            </div>
            <div className="sub-drawer">
                <img src={Parti}/>
                {user.admin ? (
                    <List sx={{marginTop: 8}}>
                        {adminLinks.items.map((listItem: any, index) =>
                            <ListItem onClick={() => handleListItemClick(listItem.link)} button key={index}>
                                {/*<CalendarIcon style={{ width: "25px" }}/>*/}
                                <Typography sx={{fontWeight: "bold", marginLeft: "20px"}}>
                                    {listItem.title}
                                </Typography>
                            </ListItem>
                        )}
                    </List>
                ) : (
                    <List sx={{marginTop: 8}}>
                        {userLinks.items.map((listItem: any, index) =>
                            <ListItem onClick={() => handleListItemClick(listItem.link)} button key={index}>
                                {/*<CalendarIcon style={{ width: "25px" }}/>*/}
                                <Typography sx={{fontWeight: "bold", marginLeft: "20px"}}>
                                    {listItem.title}
                                </Typography>
                            </ListItem>
                        )}
                    </List>
                )}
            </div>
            {/*<PartiIcon style={{ position: "absolute", width: "100px", bottom: "50px", left: "50%", transform: "translateX(-50%)" }}/>*/}
        </MaterialUiDrawer>
    );
}

export default Drawer;
