import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {Grid, TextField, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import ImageUpload from "../../ImageUpload";
import {useTranslation} from "react-i18next";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import ReactQuill from "react-quill";

interface Props {
    onSubmit: any;
    event: any;
}

const UpdateEventForm = (props: Props) => {

    const {t, i18n} = useTranslation();

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    useEffect(() => {
        console.log(editorState);
    }, [editorState]);


    const digitsOnly = (value: any) => /^\d+$/.test(value);
    const validationSchema = yup.object({
        title: yup.string().required(`${t("app.error.title", "Titel ist erforderlich")}`),
        description: yup.string().required(`${t("app.error.description", "Beschreibung ist erforderlich")}`),
        date_start: yup.string().required(`${t("app.error.startDate", "Ereignisstart ist erforderlich")}`),
        date_end: yup.string().required(`${t("app.error.endDate", "Ereignisende ist erforderlich")}`),
        subtitle_1: yup.string().required(`${t("app.error.subtitle1", "Titel 1 ist erforderlich")}`),
        location: yup.object({
            name: yup.string().required(`${t("app.error.location", "Lokation ist erforderlich")}`),
            street: yup.string().required(`${t("app.error.street", "Straße ist erforderlich")}`),
            buildNumber: yup.string().required(`${t("app.error.house", "Hausnr. ist erforderlich")}`),
            city: yup.string().required(`${t("app.error.city", "Stadt ist erforderlich")}`),
            zipCode: yup.string().required(`${t("app.error.zip", "Plz ist erforderlich")}`).test(`${t("app.error.zipValidation", "Plz muss eine Zahl sein")}`, digitsOnly)
        })
    });

    const formik = useFormik({
        initialValues: {
            title: props.event.title,
            description: props.event.description,
            subtitle_1: props.event.subtitle_1,
            subtitle_2: props.event.subtitle_2,
            location: {
                name: props.event.location.name,
                street: props.event.location.street,
                buildNumber: props.event.location.buildNumber,
                city: props.event.location.city,
                zipCode: props.event.location.zipCode
            },
            date_start: props.event.date.split(".").reverse().join("-") + "T" + props.event.time,
            date_end: props.event.end_date.split(".").reverse().join("-") + "T" + props.event.end_time,
            image: props.event.image,

            //date_start: "2022-02-01T00:00",
            //date_end: "2022-02-02T00:30",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("data", values)
            props.onSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>


            <ImageUpload
                imageFile={(e: any) => formik.setFieldValue("image", e)}
            />

            <TextField
                fullWidth
                label={t("app.event.title", "Titel")}
                name="title"
                margin="normal"
                variant="standard"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                style={{width: "540px"}}
            />

            {/*<TextField*/}
            {/*    fullWidth*/}
            {/*    multiline*/}
            {/*    rows={4}*/}
            {/*    label={t("app.event.description", "Beschreibung")}*/}
            {/*    name="description"*/}
            {/*    margin="normal"*/}
            {/*    variant="standard"*/}
            {/*    value={formik.values.description}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    error={formik.touched.description && Boolean(formik.errors.description)}*/}
            {/*    helperText={formik.touched.description && formik.errors.description}*/}
            {/*    style={{width: "540px"}}/>*/}

            <Typography>{t("app.event.description", "Beschreibung")}</Typography>
            <div style={{border: "1px solid black", padding: '2px'}}>
                <ReactQuill theme="snow" value={formik.values.description} onChange={formik.handleChange('description')}/>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t("app.event.startDate", "Ereignisstart")}
                        name="date_start"
                        margin="normal"
                        variant="standard"
                        type="datetime-local"
                        InputLabelProps={{shrink: true}}
                        value={formik.values.date_start}
                        onChange={formik.handleChange}
                        error={formik.touched.date_start && Boolean(formik.errors.date_start)}
                        helperText={formik.touched.date_start && formik.errors.date_start}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t("app.event.endDate", "Ereignisende")}
                        name="date_end"
                        margin="normal"
                        variant="standard"
                        type="datetime-local"
                        InputLabelProps={{shrink: true}}
                        value={formik.values.date_end}
                        onChange={formik.handleChange}
                        error={formik.touched.date_end && Boolean(formik.errors.date_end)}
                        helperText={formik.touched.date_end && formik.errors.date_end}/>
                </Grid>
            </Grid>

            <TextField
                fullWidth
                label={t("app.event.titel1", "Titel 1")}
                name="subtitle_1"
                margin="normal"
                variant="standard"
                value={formik.values.subtitle_1}
                onChange={formik.handleChange}
                error={formik.touched.subtitle_1 && Boolean(formik.errors.subtitle_1)}
                helperText={formik.touched.subtitle_1 && formik.errors.subtitle_1}
                style={{width: "540px"}}
            />

            <TextField
                fullWidth
                label={t("app.event.titel2", "Titel 2")}
                name="subtitle_2"
                margin="normal"
                variant="standard"
                value={formik.values.subtitle_2}
                onChange={formik.handleChange}
                helperText={formik.touched.subtitle_2 && formik.errors.subtitle_2}
                style={{width: "540px"}}
            />

            <TextField
                fullWidth
                label={t("app.event.locationName", "Lokation")}
                name="location.name"
                margin="normal"
                variant="standard"
                value={formik.values.location.name}
                onChange={formik.handleChange}
                error={formik.touched.location?.name && Boolean(formik.errors.location?.name)}
                helperText={formik.touched.location?.name && formik.errors.location?.name}
                style={{width: "540px"}}
            />


            <TextField
                fullWidth
                label={t("app.event.street", "Straße")}
                name="location.street"
                margin="normal"
                variant="standard"
                value={formik.values.location.street}
                onChange={formik.handleChange}
                error={formik.touched.location?.street && Boolean(formik.errors.location?.street)}
                helperText={formik.touched.location?.street && formik.errors.location?.street}
                style={{width: "257px"}}
            />

            <TextField
                fullWidth
                label={t("app.event.houseNo", "Hausnr.")}
                name="location.buildNumber"
                margin="normal"
                variant="standard"
                value={formik.values.location.buildNumber}
                onChange={formik.handleChange}
                error={formik.touched.location?.buildNumber && Boolean(formik.errors.location?.buildNumber)}
                helperText={formik.touched.location?.buildNumber && formik.errors.location?.buildNumber}
                style={{width: "257px"}}
            />

            <TextField
                fullWidth
                label={t("app.event.zipCode", "Plz")}
                name="location.zipCode"
                margin="normal"
                variant="standard"
                value={formik.values.location.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.location?.zipCode && Boolean(formik.errors.location?.zipCode)}
                helperText={formik.touched.location?.zipCode && formik.errors.location?.zipCode}
                style={{width: "257px"}}
            />

            <TextField
                fullWidth
                label={t("app.event.city", "Stadt")}
                name="location.city"
                margin="normal"
                variant="standard"
                value={formik.values.location.city}
                onChange={formik.handleChange}
                error={formik.touched.location?.city && Boolean(formik.errors.location?.city)}
                helperText={formik.touched.location?.city && formik.errors.location?.city}
                style={{width: "257px"}}
            />

            <div style={{textAlign: "center"}}>
                <LoadingButton
                    sx={{mt: 2}}
                    type="submit"
                    variant="contained">
                    {t("app.organizer.update", "aktualisieren")}
                </LoadingButton>
            </div>

        </form>
    );
}

export default UpdateEventForm;
