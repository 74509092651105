import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./assets/locales/en.json"
import de from "./assets/locales/de.json"

// i18n.changeLanguage('en-US');

const resources: any = {
    de: { translation: de },
    en: { translation: en }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }

    });

export default i18n;
