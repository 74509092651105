import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    CircularProgress,
    Divider
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {useHistory} from "react-router-dom";
import {createTask, deleteTask, getTasks} from "../../redux/actions/tasks";
import {groupTasksByCategory} from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import CreateTaskForm from "../../components/Forms/CreateTaskForm";
import {confirm} from "../../components/ConfirmDialog/confirm";
import { getCategories } from "../../redux/actions/categories";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

interface Props {

}

function Tasks(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();


    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const [groupedTasks, setGroupedTasks] = useState<Array<any>>([]);
    const tasks = useSelector((state: RootState) => state.tasks.tasks);

    useEffect(() => {
        setGroupedTasks(groupTasksByCategory(tasks));
    }, [tasks])

    const fetchData = () => {
        dispatch(getTasks()).then((res: any) => {
            dispatch(getCategories()).then((res: any) => {
                setLoading(false);
            }).catch((e: any) => {
                setLoading(false);
            });
        }).catch((e: any) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handlePopupClose = () => {
        setOpen(false);
    };

    const handleCreateTaskClick = () => {
        setOpen(true);
    };

    const onCreateTaskFormSubmit = (values: any) => {
        dispatch(createTask(values))
            .then((res: any) => {
                fetchData();
                handlePopupClose()
                toast.success(`${t("app.error.taskCreated", "Eine neue Aufgabenvorlage wurde erstellt")}`);
            });
    }

    const onDeleteTask = async (task: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteTask(task.id))
            .then((res: any) => {
                fetchData();
                toast.success(`${t("app.error.taskDeleted", "Die Aufgabenvorlage wurde gelöscht")}`);
    });
    }

    return (
        <Container maxWidth="lg">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem'}}>
                <button className="black-button" onClick={() => history.push(`/categories`)}>{t("app.category.categories", "Kategorien")}</button>
                <button className="yellow-button" onClick={handleCreateTaskClick}>{t("app.taskTemplate.createTask", "Aufgabe erstellen")}</button>
            </div>
            {loading ? (
                <div className="loader-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Box sx={{ mt: 3}}>
                        {!!groupedTasks.length &&
                        groupedTasks.map((group) => {
                            return (
                                <>
                                <Box sx={{ textAlign: "left", mx: "auto", mt: 5 }}>
                                    <Typography sx={{ fontSize: "1.2rem", mt: 2 }}>
                                        {group.categoryTitle}
                                    </Typography>
                                    {group.data.map((task: any) => {
                                        return (
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                                <Typography sx={{ fontSize: "1.3rem", maxWidth: "200px" }}>
                                                    {task.title}
                                                </Typography>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <button style={{marginRight: '5px'}} className="yellow-button" onClick={() => history.push(`/tasks/${task.id}`)}>{t("app.taskTemplate.editTask", "Aufgabe bearbeiten")}</button>
                                                    <button className="grey-button" onClick={() => onDeleteTask(task)}>{t("app.organizer.delete", "löschen")}</button>
                                                </div>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            <Divider style={{marginTop: '10px'}}/>
                                </>
                            )
                        })}
                    </Box>

                    <Dialog open={open} onClose={handlePopupClose}>
                        <DialogTitle>
                            {t("app.taskTemplate.createTask", "Aufgabe erstellen")}
                            <IconButton
                                aria-label="close"
                                onClick={handlePopupClose}
                                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ minWidth: "400px"}}>
                            <CreateTaskForm onSubmit={onCreateTaskFormSubmit} />
                        </DialogContent>
                    </Dialog>
                </>
            )}

        </Container>
    );
}

export default Tasks;
