import React, {useState} from 'react';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import {ReactComponent as PartiLogo} from "../../assets/icons/parti_logo.svg";
import CustomInput from "../../components/CustomInput";
import {signup} from "../../redux/actions/auth";
import {useTranslation} from "react-i18next";

interface Props {

}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignUp(props: Props) {
    let query = useQuery();

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();


    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        firstname: yup.string().required(`${t("app.error.firstNameRequired", "Vorname ist erforderlich")}`),
        lastname: yup.string().required(`${t("app.error.lastNameRequired", "Nachname ist erforderlich")}`),
        email: yup.string().email(`${t("app.error.validEmail", "Geben Sie eine gültige E-Mail ein")}`).required(`${t("app.error.emailRequired", "E-Mail ist erforderlich")}`),
        password: yup.string().required(`${t("app.error.passwordRequired", "Passwort ist erforderlich")}`),
        language: yup.string().required(`${t("app.error.languageRequired", "Sprache ist erforderlich")}`),
    });

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: query.get("email") || "",
            password: "",
            language: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(signup(values))
                .then((res: any) => {
                    history.push("/login");
                });
        }
    });

    return (
        <React.Fragment>
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>

                <PartiLogo style={{width: "80px"}}/>

                <Box
                    noValidate
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        mt: 3, maxWidth: "415px",
                        ['@media (max-width:756px)']: {
                            maxWidth: "80vw",
                        }
                    }}>

                    <CustomInput
                        style={{marginTop: "3rem"}}
                        margin="normal"
                        required
                        fullWidth
                        id="firstname"
                        placeholder={t("app.user.firstName", "Vorname")}
                        name="firstname"
                        autoFocus
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    />
                    {formik.touched.firstname && formik.errors.firstname &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.firstname}</FormHelperText>}

                    <CustomInput
                        style={{marginTop: "1rem"}}
                        margin="normal"
                        required
                        fullWidth
                        name="lastname"
                        placeholder={t("app.user.lastName", "Nachname")}
                        id="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    />
                    {formik.touched.lastname && formik.errors.lastname &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.lastname}</FormHelperText>}


                    <CustomInput
                        style={{marginTop: "1rem"}}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        placeholder={t("app.createOrganisation.email", "Email")}
                        name="email"
                        autoFocus
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                    />
                    {formik.touched.email && formik.errors.email &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.email}</FormHelperText>}

                    <CustomInput
                        style={{marginTop: "1rem"}}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        placeholder={t("app.createOrganisation.password", "Passwort")}
                        type="password"
                        id="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                    />
                    {formik.touched.password && formik.errors.password &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.password}</FormHelperText>}

                    <FormControl
                        fullWidth
                        style={{display: 'flex', width: '200px', margin: 'auto'}}
                        margin="normal"
                        variant="standard"
                        error={formik.touched.language && Boolean(formik.errors.language)}>
                        <InputLabel
                            id="language-input-label">{t("app.user.chooseLanguage", "Sprache wählen")}:</InputLabel>
                        <Select
                            labelId="language-input-label"
                            id="language-input"
                            label={t("app.user.language", "Sprache")}
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}>
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="de">German</MenuItem>
                        </Select>
                        <FormHelperText>{formik.touched.language && formik.errors.language}</FormHelperText>
                    </FormControl>


                    <button className="black-button" type="submit"
                            style={{padding: ".6rem 1.7rem", marginTop: "2rem", fontSize: "1rem"}}>{t( "app.user.register", "registrieren")}
                    </button>
                    <Typography><Link to="/login" className="signUp">{t("app.user.signIn", "Einloggen")}
                    </Link>{t("app.user.haveAnAcc", "wenn Sie ein Konto haben")}</Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default SignUp;
