import axios from 'axios';
import {toast} from "react-hot-toast";
// const baseURL = process.env.BASE_URL;

export const baseUrl = process.env.REACT_APP_API_URL || "http://ec2-3-123-43-238.eu-central-1.compute.amazonaws.com:3000";
// export const baseUrl = "http://localhost:3000";


const instance = axios.create({
    baseURL: baseUrl
});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {

    let errorMessage = "There was an issue!";
    if(error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message
    }
    if(error?.response?.data?.error_description) {
        errorMessage = error?.response?.data?.error_description
    }
    toast.error(errorMessage);


    return Promise.reject(error);
});

export default instance;
