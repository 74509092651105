import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Container, Dialog, DialogContent, DialogTitle, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {ReactComponent as OkIcon} from "../../assets/icons/ok.svg";
import {useHistory, useParams} from "react-router-dom";
import {createEvent, getEvents, updateEvent, deleteEvent} from "../../redux/actions/events";
import { getOrganisationById } from '../../redux/actions/organisations';
import { getCategoriesByOrganisation } from '../../redux/actions/categories';
import {groupEventsByMonth} from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import CreateEventForm from "../../components/Forms/CreateEventForm";
import UpdateEventForm from "../../components/Forms/UpdateEventForm";
import {useTranslation} from "react-i18next";
import {confirm} from "../../components/ConfirmDialog/confirm";
import {toast} from "react-hot-toast";

interface Props {

}

function Events(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {organisationId}: any = useParams();
    const {eventId}: any = useParams();

    const {t, i18n} = useTranslation();

    const events = useSelector((state: RootState) => state.events.events) || [];
    const organisation = useSelector((state: RootState) => state.organisations.organisation);
    const user = useSelector((state: RootState) => state.auth.user);

    const [groupedEvents, setGroupedEvents] = useState<any>([]);
    const [showPastEvents, setShowPastEvents] = useState<any>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [editingEvent, setEditingEvent] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true)

    const fetchEvents = () => {
        dispatch(getEvents(organisationId)).then((res: any) => {
            dispatch(getOrganisationById(organisationId)).then((res: any) => {
                if(isOrganisationAdmin(user.adminOfOrganisationIds) || user.admin){
                    dispatch(getCategoriesByOrganisation(organisationId)).then((res: any) => {
                        setLoading(false)
                    }).catch((error: any) => {
                        setLoading(false)
                    });
                }else{
                    setLoading(false)
                }
            }).catch((error: any) => {
                setLoading(false)
            });
        }).catch((error: any) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setGroupedEvents(groupEventsByMonth(events));
    }, [events])

    useEffect(() => {
        fetchEvents();
    }, [])

    const handlePopupClose = () => {
        setOpen(false);
        setEditingEvent(null)
    };

    const handleCreateEventClick = () => {
        setEditingEvent(null);
        setOpen(true);
    }

    const handleEditEventClick = (event: any) => {
        setEditingEvent(event);
        setOpen(true);
    };

    const onCreateEventFormSubmit = (values: any) => {
        dispatch(createEvent(organisationId, {...values, organization_id: parseInt(organisationId)}))
            .then((res: any) => {
                fetchEvents();
                handlePopupClose();
                toast.success(`${t("app.error.newEvent", "Neue Veranstaltung wurde erstellt")}`);
            });
    }

    const onUpdateEditFormSubmit = (values: any) => {
        dispatch(updateEvent(editingEvent.id, values))
            .then((res: any) => {
                fetchEvents();
                handlePopupClose();
                toast.success(`${t("app.error.updateEvent", "Die Veranstaltung wurde aktualisiert")}`);
            });
    }
    const onDeleteEvent = async (event: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteEvent(event.id))
            .then((res: any) => {
                fetchEvents();
                toast.success(`${t("app.error.deleteEvent", "Die Veranstaltung wurde gelöscht")}`);
            });
    }

    const isOrganisationAdmin = (organisationIds: number[]) => {
        return organisationIds.some((id: number) => id === parseInt(organisationId));
    }

    return (
        <Container maxWidth="lg">

            {loading ? (
                <div className="loader-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div style={{display: "flex", justifyContent:'space-between', marginTop: '2rem'}}>
                        <button className="black-button" onClick={() => setShowPastEvents(!showPastEvents)}>
                            {showPastEvents ? `${t(  "app.events.hidePastEvents", "Vergangene Events ausblenden")}` : `${t("app.events.showPastEvents", "Vergangene Events anzeigen")}`}
                        </button>
                        <span>{organisation && organisation.title}</span>
                        <>
                            {!user.admin && !isOrganisationAdmin(user.adminOfOrganisationIds) && (<span></span>)}
                            {user.admin && (
                                <button className="yellow-button" onClick={handleCreateEventClick}>{t("app.events.createEvent", "Ereignis erstellen")}</button>
                            )}
                            {!user.admin &&
                                isOrganisationAdmin(user.adminOfOrganisationIds) && (
                                    <button className="yellow-button" onClick={handleCreateEventClick}>{t("app.events.createEvent", "Ereignis erstellen")}</button>
                                )
                            }
                        </>
                    </div>

                    {groupedEvents.map((group: any) => {

                        let showGroup = true
                        // count the events that are in the month group
                        const numEvents = group.data.length
                        const numPastEvents = group.data.filter((e: any) => e.days_left < 0).length
                        // dont show group headers with empty events
                        if(numEvents === 0) showGroup = false
                        if((numEvents - numPastEvents) === 0 && !showPastEvents) showGroup = false

                        return (
                            showGroup && <TableContainer sx={{ mt: 2 }}>
                                <Table sx={{ 'th': { fontSize: ".8rem", fontWeight: "bold", borderColor: "black" } }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ p: 0, width: "100px" }}>{group.group}</TableCell>
                                            <TableCell>{t("app.events.createEvent.time","Zeit")}</TableCell>
                                            <TableCell sx={{ p: 0, width: "180px" }}>{t("app.events.createEvent.title", "Titel")}</TableCell>
                                            <TableCell>{t("app.events.createEvent.organizer", "Veranstalter")}</TableCell>
                                            <TableCell>{t("app.events.createEvent.exclusive", "Exklusiv")}</TableCell>
                                            <TableCell>{t("app.events.createEvent.hidden", "Versteckt")}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {group.data.map((event: any) => {
                                            let show = true
                                            // if the event is in the past and showing
                                            // events is switched off
                                            if(event.days_left < 0 && !showPastEvents) {
                                                show = false
                                            }
                                            return (
                                                show && <TableRow sx={{ 'td': {border:"none", py: 3}, opacity: event.days_left >= 0 ? 1 : .5 }}>
                                                    <TableCell align="left" sx={{ p: 0, width: "100px" }}>
                                                        <img src={event.image} width="100px" height="100px" alt=""/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontSize: "4.6rem", fontWeight: "bold", lineHeight: "5rem" }}>
                                                            {event.day}
                                                        </Typography>
                                                        <Typography sx={{fontWeight: "bold" }}>
                                                            {event.date} / {event.time.split(":")[0] + "  Uhr  "}
                                                        </Typography>
                                                        <Typography sx={{fontWeight: "bold", textTransform: 'capitalize'}}>
                                                            {event.location?.name}, {event.location?.city}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0, width: "180px" }}>
                                                        <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold", textTransform: 'capitalize' }}>
                                                            {event.title}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: ".8rem", fontWeight: "bold"}}>
                                                            {event.subtitle_1}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: ".8rem", fontWeight: "bold"}}>
                                                            {event.subtitle_2}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell> </TableCell>
                                                    <TableCell>
                                                        {!event.publictasks && <OkIcon width={50} height={50} fill={"black"} />}
                                                    </TableCell>
                                                    <TableCell>
                                                        {!event.visible && <OkIcon width={50} height={50} fill={"black"} />}
                                                    </TableCell>
                                                    <TableCell style={{display: 'flex', flexDirection: 'column'}}>
                                                        <button
                                                            style={{marginBottom: '10px'}}
                                                            className="yellow-button"
                                                            onClick={() => history.push(`/organisations/${organisationId}/events/${event.id}`)}
                                                        >
                                                            {t(  "app.event.editTasks", "Aufgaben editieren")}
                                                        </button>
                                                        {(
                                                            <>
                                                                {event.days_left > 0 ?
                                                                    <>
                                                                        <button
                                                                            style={{marginBottom: '10px'}}
                                                                            className="yellow-button"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditEventClick(event);
                                                                            }}>{t("app.event.editEventData", "Konzertdetails ändern")}</button>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <button
                                                                            disabled={true}
                                                                            style={{marginBottom: '10px', opacity: .5}}
                                                                            className="yellow-button"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditEventClick(event);
                                                                            }}>{t("app.event.editEventData", "Konzertdetails ändern")}</button>
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                      {(user.admin || isOrganisationAdmin(user.adminOfOrganisationIds)) &&  (
                                                        <>
                                                          {event.days_left > 0 ?
                                                            <>
                                                              <button className="grey-button"
                                                                      onClick={() => onDeleteEvent(event)}>{t("app.organizer.delete", "löschen")}</button>
                                                            </>
                                                            :
                                                            <>
                                                              <button className="grey-button"
                                                                      disabled={true}
                                                                      style={{marginBottom: '10px', opacity: .5}}
                                                                      onClick={() => onDeleteEvent(event)}>{t("app.organizer.delete", "löschen")}</button>
                                                            </>
                                                          }
                                                        </>
                                                      )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    })}

                    <Dialog open={open}>
                        <DialogTitle>
                            {t("app.events.createEvent", "Ereignis erstellen")}

                            <IconButton
                                aria-label="close"
                                onClick={handlePopupClose}
                                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>

                            {editingEvent ?
                                editingEvent && <UpdateEventForm event={editingEvent} onSubmit={onUpdateEditFormSubmit} />
                                : <CreateEventForm onSubmit={onCreateEventFormSubmit} />}

                        </DialogContent>
                    </Dialog>
                </>
            )}

        </Container>
    );
}

export default Events;
