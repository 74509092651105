import { createTheme } from '@mui/material/styles';
import {red, yellow} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: yellow.A200
        },
        secondary: {
            main: yellow.A200
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: [
            'Circular Std Black',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default theme;
