import API from "../../utils/api";
import {FETCHED_CATEGORIES} from "../constants";

export const getCategories = (): any => async(dispatch: any) => {
    try {
        const response = await API.get(`categories`);
        dispatch({ type: FETCHED_CATEGORIES, payload: response.data.categories });
        return response;
    } catch (err) {
        return err;
    }
};

export const getCategoriesByOrganisation = (organisationId: string): any => async(dispatch: any) => {
    try {
        const response = await API.get(`organisations/${organisationId}/categories`);
        dispatch({ type: FETCHED_CATEGORIES, payload: response.data.categories });
        return response;
    } catch (err) {
        return err;
    }
};

export const createCategories = (data: any) => async(dispatch: any)  => {
    try {
        const response = await API.post(`categories`, data);
        return response;
    } catch (err) {
        return err;
    }
}

export const updateCategories = (categoryId: number, data: any) => async(dispatch: any)  => {
    try {
        const response = await API.put(`categories/${categoryId}`, data);
        return response;
    } catch (err) {
        return err;
    }
}

export const deleteCategories = (categoryId: number) => async(dispatch: any)  => {
    try {
        const response = await API.delete(`categories/${categoryId}`);
        return response;
    } catch (err) {
        return err;
    }
}

export const createCategoriesByOrganisation = (organisationId: number ,data: any) => async(dispatch: any)  => {
    try {
        const response = await API.post(`organisations/${organisationId}/categories`, data);
        return response;
    } catch (err) {
        return err;
    }
}

export const updateCategoriesByOrganisation = (organisationId: number ,categoryId: number, data: any) => async(dispatch: any)  => {
    try {
        const response = await API.put(`organisations/${organisationId}/categories/${categoryId}`, data);
        return response;
    } catch (err) {
        return err;
    }
}

export const deleteCategoriesByOrganisation = (organisationId: number ,categoryId: number) => async(dispatch: any)  => {
    try {
        const response = await API.delete(`organisations/${organisationId}/categories/${categoryId}`);
        return response;
    } catch (err) {
        return err;
    }
}



