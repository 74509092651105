import React, {useState} from 'react';
import {Box, Typography, FormHelperText} from "@mui/material";
import {useDispatch} from "react-redux";
import {useHistory, Link} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import {ReactComponent as PartiLogo} from "../../assets/icons/parti_logo.svg";
import CustomInput from "../../components/CustomInput";
import {login} from "../../redux/actions/auth";
import {useTranslation} from "react-i18next";

interface Props {

}

function Login(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();


    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        email: yup.string().email(`${t("app.error.validEmail", "Geben Sie eine gültige E-Mail ein")}`).required(`${t("app.error.emailRequired", "E-Mail ist erforderlich")}`),
        password: yup.string().required(`${t("app.error.passwordRequired", "Passwort ist erforderlich")}`),
    });


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(login(values))
                .then((res: any) => {
                    history.push("/organisations");
                });
        }
    });

    return (
        <React.Fragment>
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>

                <PartiLogo style={{width: "80px"}}/>

                <Box
                    noValidate
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        mt: 3, maxWidth: "415px",
                        ['@media (max-width:756px)']: {
                            maxWidth: "80vw",
                        }
                    }}>

                    <CustomInput
                        style={{marginTop: "3rem"}}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        placeholder={t("app.createOrganisation.email", "Email")}
                        name="email"
                        autoFocus
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                    />
                    {formik.touched.email && formik.errors.email &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.email}</FormHelperText>}

                    <CustomInput
                        style={{marginTop: ".6rem"}}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        placeholder={t("app.createOrganisation.password", "Passwort")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                    />
                    {formik.touched.password && formik.errors.password &&
                        <FormHelperText style={{color: 'red'}}>{formik.errors.password}</FormHelperText>}

                    <button className="black-button" type="submit" style={{
                        padding: ".6rem 1.7rem",
                        marginTop: "2rem",
                        fontSize: "1rem"
                    }}>{t("app.user.signIn", "Einloggen")}</button>
                    <Typography>
                        <Link to="/signup" className="signUp">{t("app.user.signUp", "Anmelden")}
                    </Link>{t("app.user.dontHaveAnAcc", "wenn Sie kein Konto haben")}</Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Login;
