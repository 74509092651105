import React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import {useTranslation} from "react-i18next";
import ImageUpload from "../../ImageUpload";

interface Props {
    onSubmit: any;
}


const CreateOrganisationForm = (props: Props) => {

    const {t, i18n} = useTranslation();

    const digitsOnly = (value: any) => /^\d+$/.test(value);
    const validationOrganisationSchema = yup.object({
        title: yup.string().required(`${t("app.error.title", "Titel ist erforderlich")}`),
        email: yup.string().email(`${t("app.error.validEmail", "Geben Sie eine gültige E-Mail ein")}`).required(`${t("app.error.emailRequired", "E-Mail ist erforderlich")}`),
        city: yup.string().required(`${t("app.error.city", "Stadt ist erforderlich")}`),
        address: yup.string().required(`${t("app.error.address", "Anschrift ist erforderlich")}`),
        zipCode: yup.string().required(`${t("app.error.zip", "Plz ist erforderlich")}`).test(`${t("app.error.zipValidation", "Plz muss eine Zahl sein")}`, digitsOnly)
    });


    const formik = useFormik({
        initialValues: {
            title: "",
            email: "",
            city: "",
            address: "",
            zipCode: "",
            image: null
        },
        validationSchema: validationOrganisationSchema,
        onSubmit: (values) => {
          const payload = new FormData();

          payload.append("title", values.title);
          payload.append("email", values.email);
          payload.append("city", values.city);
          payload.append("address", values.address);
          payload.append("zipCode", values.zipCode);
          // @ts-ignore
          payload.append("image", values.image);
            props.onSubmit(payload);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <ImageUpload
                imageFile={(e: any) => formik.setFieldValue("image", e)}
            />

            <TextField
                fullWidth
                label={t("app.createOrganisation.title", "Titel")}
                name="title"
                margin="normal"
                variant="standard"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                style={{width: "540px"}}
            />

            <TextField
                fullWidth
                label={t("app.createOrganisation.email", "Email")}
                name="email"
                margin="normal"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{width: "540px"}}
            />

            <TextField
                fullWidth
                label={t("app.createOrganisation.address", "Anschrift")}
                name="address"
                margin="normal"
                variant="standard"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                style={{width: "540px"}}
            />

            <TextField
                fullWidth
                label={t("app.createOrganisation.zip", "Plz")}
                name="zipCode"
                margin="normal"
                variant="standard"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                style={{width: "265px"}}
            />

            <TextField
                fullWidth
                label={t("app.createOrganisation.city", "Stadt")}
                name="city"
                margin="normal"
                variant="standard"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                style={{width: "265px"}}
            />

            <div style={{textAlign: "center"}}>
                <LoadingButton
                    sx={{mt: 4}}
                    type="submit"
                    variant="contained">
                    {t("app.organizer.create", "erstellen")}
                </LoadingButton>
            </div>

        </form>
    );
}

export default CreateOrganisationForm;
