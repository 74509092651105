import React from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers/rootState";
import {useTranslation} from "react-i18next";
import ImageUpload from "../../ImageUpload";

interface Props {
    onSubmit: any;
}


const CreateCategoryForm = (props: Props) => {

    const {t, i18n} = useTranslation();

    const digitsOnly = (value: any) => /^\d+$/.test(value);
    const validationSchema = yup.object({
        title: yup.string().required(`${t("app.error.title", "Titel ist erforderlich")}`),
    });


    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <TextField
                fullWidth
                label={t("app.createOrganisation.title", "Titel")}
                name="title"
                margin="normal"
                variant="standard"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                style={{width: "540px"}}
            />

            <div style={{textAlign: "center"}}>
                <LoadingButton
                    sx={{mt: 4}}
                    type="submit"
                    variant="contained">
                    {t("app.organizer.create", "erstellen")}
                </LoadingButton>
            </div>

        </form>
    );
}

export default CreateCategoryForm;
