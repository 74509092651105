import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container,
    Switch,
    Typography,
    CircularProgress
} from "@mui/material";
import {RootState} from "../../redux/reducers/rootState";
import {useHistory, useParams} from "react-router-dom";
import {
    fetchEventCode,
    getEventById,
    getEvents,
    getEventTasks,
    handleParticipation, inviteEventProjectLead
} from "../../redux/actions/events";
import CustomInput from "../../components/CustomInput";
import {toast} from "react-hot-toast";
import TeamOverview from "./teamOverview";
import {getCategoriesByOrganisation} from "../../redux/actions/categories";
import {useTranslation} from "react-i18next";
import EventTasks from "./eventTasks";


interface Props {

}

function Event(props: Props) {

    const history = useHistory();
    const {t, i18n} = useTranslation();

    const dispatch: any = useDispatch();
    const {organisationId, eventId}: any = useParams();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState<boolean>(true);

    // const [groupedTasks, setGroupedTasks] = useState<Array<any>>([]);

    const fetchData = () => {
        dispatch(getEventById(eventId)).then((res: any) => {
            dispatch(getEvents(organisationId)).then((res: any) => {
                dispatch(getEventTasks(eventId)).then((res: any) => {
                    dispatch(fetchEventCode(eventId)).then((res: any) => {
                      dispatch(getCategoriesByOrganisation(organisationId)).then((res: any) => {
                        setLoading(false)
                      }).catch((error: any) => {
                        setLoading(false)
                      });
                    }).catch((e: any) => {
                        setLoading(false)
                    });
                }).catch((e: any) => {
                    setLoading(false)
                });
            }).then((e: any) => {
                setLoading(false)
            });
        }).catch((e: any) => {
            setLoading(false)
        });
    }

    const events = useSelector((state: RootState) => state.events.events);
    const eventTaskSummary = useSelector((state: RootState) => state.events.event);
    const eventCode = useSelector((state: RootState) => state.events.eventCode);
    const eventTasks = useSelector((state: RootState) => state.events.eventTasks);

    const event = events.find((event: any) => event.id == eventId);

    useEffect(() => {
        fetchData();
    }, [])

    // useEffect(() => {
    //     setGroupedTasks(groupTasksByCategory(eventTasks));
    // }, [eventTasks])

    const handleOpenForParticipationChange = (e: any) => {
        const participationData = {
            "openForParticipation": e.target.checked,
            "exclusive": event.exclusive,
            "visible": event.visible
        }
        dispatch(handleParticipation(event.id, participationData))
            .then((res: any) => {
                fetchData();
            });
    }

    const handleExslusiveChange = (e: any) => {
        const participationData = {
            "openForParticipation": event.openForParticipation,
            "exclusive": e.target.checked,
            "visible": event.visible
        }
        dispatch(handleParticipation(event.id, participationData))
            .then((res: any) => {
                fetchData();
            });
    }

    const handleVisibleChange = (e: any) => {
        const participationData = {
            "openForParticipation": event.openForParticipation,
            "exclusive": event.exclusive,
            "visible": e.target.checked,
        }
        dispatch(handleParticipation(event.id, participationData))
            .then((res: any) => {
                fetchData();
            });
    }

    const handleInviteEventProjectLead = (e: any) => {
        const payload = {email: email};
        dispatch(inviteEventProjectLead(eventId, payload))
            .then((res: any) => {
                fetchData();
                toast.success(`${t("app.error.userInvited", "Benutzer eingeladen")}`);
            });
    }


    return (
        <Container maxWidth="lg">
            {/*<div style={{display: 'flex', justifyContent: 'end'}}>*/}
            {/*    <button className="yellow-button">Edit Event</button>*/}
            {/*</div>*/}
                <>
                    {(event && eventTaskSummary) && (
                        <div style={{textAlign: "center", marginTop:'1rem'}}>
                            <img src={event.image} width="300px" alt="Event image"/>
                            <Typography sx={{fontWeight: "bold"}}>
                                {event.date}
                            </Typography>
                            <Typography sx={{fontSize: "1.8rem", fontWeight: "bold"}}>
                                {event.title}
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {event.subtitle_1}
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {event.subtitle_2}
                            </Typography>

                            <Typography sx={{fontWeight: "bold", mt: 4}}>
                                {t("app.event.everybodyCanParticipate", "Jeder kann mitmachen")}
                            </Typography>

                            <Box>
                                <Switch
                                    defaultChecked={event.openForParticipation}
                                    onChange={handleOpenForParticipationChange}/>
                            </Box>

                            {event.openForParticipation && (
                                <Box>
                                    <Typography sx={{fontWeight: "bold", mt: 3}}>
                                        {t("app.event.exclusiveEvent", "Exklusive Veranstaltung")}
                                    </Typography>
                                    <Switch
                                        defaultChecked={event.exclusive}
                                        onChange={handleExslusiveChange}
                                    />
                                    {event.exclusive &&
                                        <Typography sx={{fontWeight: "bold"}}>
                                            {t("app.event.code", "Kode")} : {eventCode}
                                        </Typography>
                                    }
                                </Box>)}

                            {event.openForParticipation && (
                                <Box>

                                    <Typography sx={{fontWeight: "bold", mt: 3}}>
                                        {t("app.event.eventVisibleToEveryone", "Event für jeden sichtbar")}
                                    </Typography>
                                    <Switch
                                        defaultChecked={event.visible}
                                        onChange={handleVisibleChange}
                                    />
                                </Box>)}

                            <Box>
                                <button
                                    style={{marginTop: "3rem"}}
                                    className="yellow-button"
                                    onClick={() => history.push(`/organisations/${organisationId}/events/${event.id}/teamOverview`)}>{t("app.event.teamOverview", "Teamübersicht")}
                                </button>
                            </Box>

                            <Typography sx={{fontWeight: "bold", mt: 3}}>
                                {t("app.event.inviteAProjectLead", "Einen Projektleitung einladen?")}
                            </Typography>

                            <CustomInput
                                style={{marginTop: "1rem", width: "500px"}}
                                name="email"
                                margin="normal"
                                value={email}
                                placeholder="Email"
                                onChange={(e: any) => setEmail(e.target.value)}/>

                            <Box>
                                <button style={{marginTop: "1rem"}} className="black-button"
                                        onClick={handleInviteEventProjectLead}>
                                    {t(  "app.event.invite", "Einladen")}
                                </button>
                            </Box>


                            <Box sx={{display: "flex", justifyContent: "space-between", width: "300px", mx: "auto", mt: 3}}>
                                <div>
                                    <Typography sx={{fontSize: "3rem", fontWeight: "bold", lineHeight: "2rem", mt: 2}}>
                                        {eventTaskSummary.tasks_assigned} / {eventTaskSummary.tasks_total}
                                    </Typography>
                                    <Typography sx={{fontWeight: "bold", mt: 2}}>
                                        {t("app.event.assignTasks", "Aufgaben vergeben")}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography sx={{fontSize: "4rem", fontWeight: "bold", lineHeight: "2rem", mt: 2}}>
                                        {eventTaskSummary.num_users}
                                    </Typography>
                                    <Typography sx={{fontWeight: "bold", mt: 2}}>
                                        {t("app.event.teamMembers", "Teammitglieder")}
                                    </Typography>
                                </div>
                            </Box>
                        </div>
                    )}
                </>
            <EventTasks/>
        </Container>
    );
}

export default Event;
