import app, {AppState} from "./app";
import {combineReducers} from "redux";
import auth, {AuthState} from "./auth";
import tasks, {TasksState} from "./tasks";
import organisations, {OrganisationsState} from "./organisations";
import events, {EventsState} from "./events";
import categories, {CategoriesState} from "./categories";

export interface RootState {
    app: AppState;
    auth: AuthState;
    tasks: TasksState;
    organisations: OrganisationsState;
    events: EventsState;
    categories: CategoriesState;
}

export default combineReducers({
    app,
    auth,
    tasks,
    organisations,
    events,
    categories
});
