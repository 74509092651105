import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";



const ConfirmDialog = ({show, proceed, confirmation, options}: any) => {

    const {t, i18n} = useTranslation();


    return(
        <Dialog
            open={show}
            onClose={() => proceed(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t("app.confirmation.confirm", "Bitte bestätigen")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t("app.confirmation", "Sind Sie sicher, dass Sie fortfahren wollen?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => proceed(false)}>{t("app.confirmDialog.cancel", "Abbrechen")}</Button>
                <Button onClick={() => proceed(true)} autoFocus>{t("app.confirmDialog.yes", "Ja")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default confirmable(ConfirmDialog);
