import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    styled,
    Typography,
    CircularProgress
} from "@mui/material";
import {createOrganisation, getOrganisations, updateOrganisation, deleteOrganisation, changeState} from "../../redux/actions/organisations";
import {RootState} from "../../redux/reducers/rootState";
import OrganisationLogo from "../../assets/images/parti-black.png"
import {useHistory} from "react-router-dom";
import CreateOrganisationForm from "../../components/Forms/CreateOrganisationForm";
import CloseIcon from '@mui/icons-material/Close';
import UpdateOrganisationForm from "../../components/Forms/UpdateOrganisationForm";
import {useTranslation} from 'react-i18next';
import {confirm} from "../../components/ConfirmDialog/confirm";
import {toast} from "react-hot-toast";

interface Props {

}

const OrganisationText = styled(Typography)(({theme}) => ({
    fontSize: "1.4rem",
    fontWeight: "bold",
    textDecoration: "underline",
    color: "black",
    '&:hover': {
        color: 'grey'
    }
}));

function Organisations(props: Props) {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [editingOrganisation, setEditingOrganisation] = useState<any>(null);

    const organisations = useSelector((state: RootState) => state.organisations.organisations);
    const user = useSelector((state: RootState) => state.auth.user);

    const fetchOrganisations = () => {
        dispatch(getOrganisations()).then((res: any) => {
            setLoading(false)
        }).catch((error: any) => {
            setLoading(false)
        });
    }

    useEffect(() => {
        fetchOrganisations();
    }, [])

    function changeBackground(e: any) {
        e.target.style.background = 'whitesmoke';
    }

    function changeBackgroundToNormal(e: any) {
        e.target.style.background = 'transparent';
    }

    const handlePopupClose = () => {
        setOpen(false);
        setEditingOrganisation(null);
    };

    const handleCreateOrganizationClick = () => {
        setEditingOrganisation(null);
        setOpen(true);
    };

    const handleEditOrganizationClick = (organisation: any) => {
        setEditingOrganisation(organisation);
        setOpen(true);
    };

    const onCreateOrganisationFormSubmit = (values: any) => {
        dispatch(createOrganisation(values))
            .then((res: any) => {
                fetchOrganisations();
                handlePopupClose();
                toast.success(`${t("app.error.newOrg", "Eine neue Organisation wurde erstellt")}`);
            });
    }

    const onUpdateOrganisationFormSubmit = (values: any) => {
        dispatch(updateOrganisation(editingOrganisation.id, values))
            .then((res: any) => {
                fetchOrganisations();
                handlePopupClose();
                toast.success(`${t("app.error.updateOrg", "Die Organisation wurde aktualisiert")}`);
            });
    }

    const onDeleteOrganisation = async (organisation: any) => {
        if (!await confirm()) {
            return;
        }
        dispatch(deleteOrganisation(organisation.id))
            .then((res: any) => {
                fetchOrganisations();
                toast.success(`${t("app.error.deleteOrg", "Die Organisation wurde gelöscht")}`);
            });
    }

    const onChangeStateDisable = async (organisation: any) => {
        if (!await confirm()) {
            return;
        }
        const changeStateData = {
            "disabled": true
        }
        dispatch(changeState(organisation.id, changeStateData))
            .then((res: any) => {
                fetchOrganisations();
                toast.success(`${t("app.error.disableOrg", "Die Organisation wurde deaktiviert")}`);
            })
    }

    const onChangeStateActivate = async (organisation: any) => {
        if (!await confirm()) {
            return;
        }
        const changeStateData = {
            "disabled": false
        }
        dispatch(changeState(organisation.id, changeStateData))
            .then((res: any) => {
                fetchOrganisations();
                toast.success(`${t("app.error.activateOrg", "Die Organisation wurde aktiviert")}`);
            })
    }

    return (
        <Container maxWidth="lg">
            {loading ? (
                <div className='loader-center'>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {user.admin && (
                        <Box sx={{ display: 'flex'}}>
                            <button className="yellow-button" onClick={handleCreateOrganizationClick}>{t("app.organizer.createOrganisation", "Organisation schaffen")}</button>
                        </Box>
                    )}

                    {organisations.map((organisation) => (
                        <Link sx={{cursor: "pointer"}}>
                            <Grid container className="org" sx={{alignItems: "center", height: '100px'}}>
                                <Grid item onClick={() => history.push(`/organisations/${organisation.id}/events`)} >
                                  {organisation.image ? (
                                    <img
                                      className="org-image"
                                      src={organisation.image}
                                      alt="Organisation Image"
                                    ></img>
                                  ) : (
                                    <img src={OrganisationLogo}/>
                                  )}
                                </Grid>
                                <Grid item sx={{textAlign: "center"}}  onClick={() => history.push(`/organisations/${organisation.id}/events`)}>
                                    <OrganisationText>{organisation.title}</OrganisationText>
                                </Grid>
                                <Grid>
                                    {user.admin && (
                                        <>
                                            <button
                                                className="yellow-button"
                                                style={{marginRight: '5px', marginLeft: '5px'}}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditOrganizationClick(organisation)
                                                }}>{t("app.organizer.toEdit", "bearbeiten")}
                                            </button>
                                            {organisation.disabledAt === null ? (
                                                <button className="grey-button" onClick={() => onChangeStateDisable(organisation)}>
                                                    {t("app.organizer.disable","deaktivieren",)}
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        style={{marginRight: '5px'}}
                                                        className="grey-button"
                                                            onClick={() => onChangeStateActivate(organisation)}>
                                                        {t("app.organizer.activate", "aktivieren")}
                                                    </button>
                                                    <button className="grey-button" onClick={() => onDeleteOrganisation(organisation)}>
                                                        {t("app.organizer.delete", "löschen")}
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Link>
                    ))}

                    <Dialog open={open}>
                        <DialogTitle>
                            {t("app.organisation.dialogTitle", "Bitte geben Sie Ihre Organisationsdaten ein")}

                            <IconButton
                                aria-label="close"
                                onClick={handlePopupClose}
                                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>

                            {editingOrganisation ?
                                editingOrganisation && <UpdateOrganisationForm organisation={editingOrganisation} onSubmit={onUpdateOrganisationFormSubmit} />
                                    : <CreateOrganisationForm onSubmit={onCreateOrganisationFormSubmit} />}

                        </DialogContent>
                    </Dialog>
                </>
            )}

        </Container>
    );
}

export default Organisations;
