

export const getQueryParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
     buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export const convertObjectToFormData = (object: any) => {
  const formData = new FormData();
  buildFormData(formData, object);
    return formData;
}

export const parsePageContent = (content: any, phrase: any) => {
    console.log(content);
    let parsedContent = content.replaceAll("\n", "<br/>").replaceAll("~Neni", "<br /> Neni");
    if(phrase) {
        let regex = new RegExp(phrase, "ig");
        parsedContent = parsedContent.replaceAll(regex, "<b>"+ phrase +"</b>");
    }
    return parsedContent;
}

export const parseJwt = (token: any) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


export const groupEventsByMonth = (array: any) => {
    const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August","September", "Oktober", "November", "Dezember"];

  const groupByMonth = array.reduce((group:any, event:any) => {
    const eventMonth = parseInt(event.date.split(".")[1])
    const month = months[eventMonth - 1];
    group[month] = group[month] ?? [];
    group[month].push(event);
    return group;
  }, {});

  const groups = [];
  for(const month of months) {
    const data = groupByMonth[month];
    if (data) {
      groups.push({
        data: data.sort((a: any, b: any) => Date.parse(a.dateTime) - Date.parse(b.dateTime)),
        group: month
      })
    }
  }
    return groups
}

export const groupTasksByCategory = (array: any) => {
    let groups = array.reduce(function (r: any, o: any, i: any) {
        const m = o.categoryId;
        const title = o.category.title;
        r[m]
            ? r[m].data.push(o)
            : (r[m] = { group: m, categoryId: o.categoryId, categoryTitle: title, data: [o] });

        return r;
    }, {});

    let result = Object.keys(groups).map(function (k) {
        return groups[k];
    });
    return result;
};
