import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    ButtonProps,
    IconButton,
    styled,
    Toolbar,
    ButtonGroup,
    Menu,
    MenuItem,
    ListItemIcon,
    Tooltip,
    Box,
    Divider,
    Avatar, SwipeableDrawer, ListItemText, ListItem, List, Typography
} from "@mui/material";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import {RootState} from "../../../redux/reducers/rootState";
import {useHistory} from "react-router-dom";
import {confirm} from "../../ConfirmDialog/confirm";
import {useTranslation} from "react-i18next";
import {ReactComponent as LeftIcon} from "../../../assets/icons/web_link_arrow_L.svg";
import {logout} from "../../../redux/actions/auth";
import Profile from "../../../assets/icons/profile.png"
import {Logout, Mail, MenuSharp, PersonAdd, Settings} from "@mui/icons-material";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/calendar_icon.svg";
import Parti from "../../../assets/images/parti.png";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

const WhiteButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        borderColor: "white",
        backgroundColor: theme.palette.primary.main,
    },
}));

const WhiteTextButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: "white",
    border: "none",
    backgroundColor: "transparent",
}));

type Anchor = 'left'


const Header = (props: AppBarProps) => {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const isDrawerOpen = useSelector((state: RootState) => state.app.isDrawerOpen);
    const user = useSelector((state: RootState) => state.auth.user);
    const isMobile = window.innerWidth < 830;

    const [state, setState] = React.useState({
        // top: false,
        left: false,
        // bottom: false,
        // right: false,
    });


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        if (!await confirm()) {
            return;
        }
        dispatch(logout());
        history.push("/login");
    }

    const onLanguageChange = (lang: any) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    }

    const handleListItemClick = (path: string) => {
        history.push(path);
    }

    const adminLinks = {
        items: [
            { link: "/organisations", title: `${t("app.home.organizer", "Veranstalter:in")}`, icon: <CalendarIcon/>},
            { link: "/tasks", title: `${t("app.home.taskTemplates", "Aufgaben Vorlagen")}`, icon:  <CalendarIcon/>},
        ]
    }

    const userLinks = {
        items: [
            { link: "/organisations", title: `${t("app.home.organizer", "Veranstalter:in")}`, icon: <CalendarIcon/>},
        ]
    }
    //SwipeableDrawer
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{'width' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {user.admin ? (
                <div className="sub-drawer">
                    <img src={Parti}/>
                <List>
                    {adminLinks.items.map((listItem: any, index) =>
                        <ListItem onClick={() => handleListItemClick(listItem.link)} button key={index}>
                            {/*<CalendarIcon style={{ width: "25px" }}/>*/}
                            <Typography sx={{ fontWeight: "bold", marginLeft: "20px" }}>
                                {listItem.title}
                            </Typography>
                        </ListItem>
                    )}
                </List>
                </div>
            ) : (
                <div className="sub-drawer">
                    <img src={Parti}/>
                <List>
                    {userLinks.items.map((listItem: any, index) =>
                        <ListItem onClick={() => handleListItemClick(listItem.link)} button key={index}>
                            {/*<CalendarIcon style={{ width: "25px" }}/>*/}
                            <Typography sx={{ fontWeight: "bold", marginLeft: "20px" }}>
                                {listItem.title}
                            </Typography>
                        </ListItem>
                    )}
                </List>
                </div>
            )}
        </Box>
    );


    return (
        <AppBar sx={{backgroundColor: "white", boxShadow: "none"}} position="fixed"
                open={isDrawerOpen}>

            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>

                {isMobile ? (
                    <div>
                        {(['left'] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <Button onClick={toggleDrawer(anchor, true)}><MenuSharp style={{color: "black"}}/></Button>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    {list(anchor)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </div>
                ): null }

                <LeftIcon className="left-icon"
                          onClick={() => history.goBack()}/>

                {user && (<p style={{marginRight: '30px'}}>{user.firstname} {user.lastname}</p>)}

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px'}}>
                    <ButtonGroup variant="text" aria-label="text button group" style={{marginRight: '15px'}}>
                        <Button style={{color: 'black', borderColor: 'black'}}
                                onClick={() => {
                                    onLanguageChange('en')
                                }}>En</Button>
                        <Button style={{color: 'black', borderColor: 'black'}}
                                onClick={() => {
                                    onLanguageChange('de')
                                }}>De</Button>
                    </ButtonGroup>

                    <React.Fragment>
                        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>

                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ml: 2}}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <img src={Profile} width='24px' height='24px'/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem
                                onClick={handleLogout}
                            >
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                {t("app.user.logout", "Ausloggen")}
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
