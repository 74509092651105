import React, {useEffect} from 'react';
import './App.scss';
import Layout from "./components/Layout";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import Login from "./containers/Login";
import {useDispatch} from "react-redux";
import Organisations from "./containers/Organisations";
import Events from "./containers/Events";
import Event from "./containers/Event";
import Tasks from "./containers/Tasks";
import Subtasks from "./containers/Subtasks";
import EventTaskSubtasks from "./containers/EventTaskSubtasks";
import SignUp from "./containers/SignUp";
import TeamOverview from "./containers/Event/teamOverview";
import Categories from "./containers/Tasks/categories";
import EventCategories from "./containers/Event/eventCategories";

function App() {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <Switch>

              <Route
                exact
                path="/"
                render={() => {
                  return (
                      <Redirect to="/organisations" />
                  )
                }}
              />
                <Route path="/signup" render={() => <SignUp/>} />
                <Route path="/login" render={() => <Login/>} />

                <Layout>
                    <Route exact path="/tasks" render={() => <Tasks/>} />
                    <Route exact path="/tasks/:taskId" render={() => <Subtasks/>} />
                    <Route exact path="/organisations" render={() => <Organisations/>} />
                    <Route exact path="/categories" render={() => <Categories/>} />
                    <Route exact path="/organisations/:organisationId/categories" render={() => <EventCategories/>} />
                    <Route exact path="/organisations/:organisationId/events" render={() => <Events/>} />
                    <Route exact path="/organisations/:organisationId/events/:eventId" render={() => <Event/>} />
                    <Route exact path="/organisations/:organisationId/events/:eventId/teamOverview" render={() => <TeamOverview/>} />
                    <Route exact path="/organisations/:organisationId/events/:eventId/tasks/:taskId" render={() => <EventTaskSubtasks/>} />
                </Layout>
            </Switch>
        </React.Fragment>
    );
}

export default App;
