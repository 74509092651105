import {Action} from "../actions/app";
import * as constants from "../constants";

export interface TasksState {
    task: any;
    tasks: Array<any>;
    taskSubtasks: Array<any>;
    taskSubtask: any;
}

const getInitialState = () => {
    return {
        tasks: [],
        task: null,
        taskSubtasks: [],
        taskSubtask: null,
    }
}

const tasks = (state: TasksState = getInitialState(), action: Action) => {
    switch (action.type) {

        case constants.FETCHED_TASKS: {
            return {...state, tasks: action.payload};
        }

        case constants.FETCHED_TASK_BY_ID: {
            return {...state, task: action.payload};
        }

        case constants.FETCHED_TASK_SUBTASKS: {
            return {...state, taskSubtasks: action.payload};
        }

        case constants.FETCHED_TASK_SUBTASK_BY_ID: {
            return {...state, taskSubtask: action.payload};
        }

        case constants.LOGGED_OUT: {
            return {...getInitialState()}
        }

        default: {
            return {...state};
        }

    }
}

export default tasks;
