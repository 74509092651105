import API from "../../utils/api";
import {LOGGED_OUT, AUTHENTICATED, MYPROFILE} from "../constants";

export const login = (data: any): any => async(dispatch: any) => {
    try {
        const response = await API.post("login", data);
        dispatch({ type: AUTHENTICATED, payload: response.data});
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response;
    } catch (err) {
        throw err;
    }
};

export const myprofile = (): any => async(dispatch: any) => {
    try {
        const response = await API.get("authstatus");
        dispatch({ type: MYPROFILE, payload: response.data});
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return response;
    } catch (err) {
        throw err;
    }
};

export const signup = (data: any): any => async(dispatch: any) => {
    try {
        const response = await API.post("signup", data);
        return response;
    } catch (err) {
        throw err;
    }
};

export const logout = (): any => async(dispatch: any) => {
    try {
        const response = await API.post("logout");
        dispatch({ type: LOGGED_OUT });
        localStorage.removeItem("user");
        return response;
    } catch (err) {
        throw err;
    }
};
